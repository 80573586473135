import React, { Component } from 'react';
import axios from 'axios';
import $ from 'jquery';
import Token from '../Config/Config.json';
import SliderLeftSide from './Slider_left_side';

import { sendAuthorizationRequest, sendTokenRequest } from "../Login/actions/sign-in";
import ReactJson from 'react-json-view'
import { dispatchLogout } from "../Login/actions/sign-out";
import { isValidSession, getAllSessionParameters, decodeIdToken } from "../Login/actions/session";
import Header from '../../Header/Header';


class LoginHome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: {},
            errors: {},
            username: '',
            message: '',
            statusCode: 0
        }
    }

    componentWillMount() {
        // See if there is a valid session.
        if (isValidSession()) {
            const session = getAllSessionParameters();
            const _tokenResponse = {
                access_token: session.ACCESS_TOKEN,
                refresh_token: session.REFRESH_TOKEN,
                scope: session.SCOPE,
                id_token: session.ID_TOKEN,
                token_type: session.TOKEN_TYPE,
                expires_in: parseInt(session.EXPIRES_IN),
            };
            this.setState({
                tokenResponse: _tokenResponse,
                idToken: decodeIdToken(session.ID_TOKEN),
                isLoggedIn: true
            });
            return;
        }

        // Reads the URL and retrieves the `code` param.
        const code = new URL(window.location.href).searchParams.get("code");

        // If a authorization code exists, sends a token request.
        if (code) {
            sendTokenRequest(code)
                .then(response => {
                    localStorage.setItem('tokenData', JSON.stringify(response))
                    console.log("TOKEN REQUEST SUCCESS", response);
                    this.setState({
                        tokenResponse: response[0],
                        idToken: response[1],
                        isLoggedIn: true
                    })
                })
                .catch((error => {
                    console.log("TOKEN REQUEST ERROR", error);
                    this.setState({ isLoggedIn: false });
                }));
        }
    }

    /**
     * Handles login button click
     */
    handleLoginBtnClick = () => {
        sendAuthorizationRequest();
    };

    onSubmit = (e) => {
        e.preventDefault();
        axios.post(`${process.env.REACT_APP_API_URL}regapi/1/api/getlogin`, {
            "mobileno": e.target.value,
            "password": e.target.value
        }).then((res) => {
            console.log(res)
            if (res.data.status === 'ok') {


            }
        })
            .catch((error) => {
                error("Somerthing Went Wrong")
            })
    }


    handleValidation() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        //phone
        if (!fields["phone"]) {
            formIsValid = false;
            errors["phone"] = "Please enter phone number";
        }
        else if (fields["phone"].length < 10) {
            formIsValid = false;
            errors["phone"] = "Phone number must be less than 10 digits";
        }
        else if (isNaN(fields["phone"])) {
            formIsValid = false;
            errors["phone"] = "Phone number must be a number";
        }

        // Password
        if (!fields["password"]) {
            formIsValid = false;
            errors["password"] = "Please fill password";
        }


        this.setState({ errors: errors });
        return formIsValid;
    }


    handleChange = (field, e) => {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({ fields });
    }

    contactSubmit(e) {
        e.preventDefault();
        // {
        var data = { mobileno: e.target.phone.value, password: e.target.password.value };
        var config = {
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}regapi/1/api/getlogin`,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
                'Content-Type': 'text/plain'
            },
            data: data
        };
        axios(config)
            .then((response) => {
                console.log(JSON.stringify(response.data));
                if (response.data.message === "Token validated successfully") {
                    window.$('#exampleModalCenter').modal('show');
                    const name = response.data.pd.username
                    localStorage.setItem('username', name)
                    this.setState({
                        username: response.data.pd.username,
                        message: response.data.message,
                        statusCode: response.data.statusCode
                    })

                }
                else if (response.data.statusCode === 201) {
                    this.setState({
                        message: response.data.message,
                        statusCode: response.data.statusCode
                    })
                    window.$('#exampleModalCenter').modal('show');
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }



    render() {
        const { tokenResponse, idToken, isLoggedIn } = this.state;
        return (
            <React.Fragment>
                <Header/>
                <div className="modal fade" id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="popup text-center">
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                    {
                                        this.state.statusCode === 201 ?
                                            <img src="./assets/img/ic_cross_mark.png" height='50px' alt="wrong" />
                                            : <img src="./assets/imag/ic_thumbs_up.svg" alt="" />
                                    }
                                    <p>{this.state.message}</p>
                                    <button type="button" className="btn  login" data-dismiss="modal">OKAY</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="sdbi-banner">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-6 ">
                                <SliderLeftSide />
                            </div>
                           
                                    <div className="col-sm-6">
                                        <div className="registration">

                                            <h2>Login Now!</h2>
                                            {/* <form name="contactform" 
                                        // onSubmit={this.contactSubmit.bind(this)}
                                        >
                                            <div className="form-group mobile-form-group">
                                                <label>Mobile No.</label>
                                                <div className="cmob">
                                                    <input type="text" className="form-control" placeholder="+91" disabled
                                                        style={{ height: "37px" }} />
                                                    <input
                                                        className="form-control registration-login"
                                                        name='phone'
                                                        refs="phone"
                                                        //    onkeypress={(e) => this.isNumber(e)}
                                                        type="tel" maxLength={10}
                                                        placeholder="Enter your mobile number"
                                                        name='phone'
                                                        // onChange={this.handleChange.bind(this, "phone")}
                                                        onBlur={() => this.handleValidation()}
                                                        value={this.state.fields["phone"]}
                                                    />
                                                    <br />
                                                   
                                                </div>
                                            </div>
                                            <div className="">
                                                <label>Password</label>
                                                <input type="password" name="password" className="form-control" placeholder="Enter your Password" required />
                                                <div className="valid-feedback">Valid.</div>
                                                <div className="invalid-feedback">Please fill out this field.</div>
                                            </div>
                                            <div className="form-group form-check">
                                                <label className="form-check-label">
                                                    <input className="form-check-input" type="checkbox" name="remember" /> Remember Me
                                                    <div className="invalid-feedback">Check this checkbox to continue.</div>
                                                </label><a className="forgot" href="!#">Forgot Password</a>
                                            </div> */}
                                            <button type="button" onClick={() => this.handleLoginBtnClick()} className="btn  login">LOGIN</button>
                                            {/* </form> */}

                                        </div>

                                        <p className="new-user">New User?<a className="register-nows" href="/registration-step-one"> Register Now</a></p>
                                    </div>
                            {/* <button className="btn btn-primary" onClick={this.handleLoginBtnClick}>LOGIN</button> */}


                        </div>
                    </div>


                    {/* } */}
                </section>

            </React.Fragment>
        )
    }
}


export default LoginHome