import React from 'react';
import Token from '../../Component/Auth/Config/Config.json';
import axios from 'axios';
import { connect } from 'react-redux';
import * as action from '../../Component/Store/Actions/Actions';
import DigitalHeader from '../DigitalHeader/DigitalHeader';
import DeFooter from '../Defooter/DeFooter';

class SearchResult extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         latestToolData: []
      }
   }
   recieveLatestToolsData = () => {
      var data = { toolscategorycode: 4 };
      var config = {
         method: 'post',
         url: `${process.env.REACT_APP_API_URL}/digitalapi/1/api/getcategoryrelatedtools`,
         headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
            'Content-Type': 'text/plain'
         },
         data: data
      };

      axios(config)
         .then((response) => {
            console.log(JSON.stringify(response.data));
            this.setState({
               latestToolData: response.data.pd
            })
         })
         .catch(function (error) {
            console.log(error);
         });
   }
   componentDidMount() {
      this.recieveLatestToolsData();
   }
   render() {
      return (
         <React.Fragment>
            <DigitalHeader/>
            <main style={{ background: "#F9F9F9" }}>
               <section className="bread_cum">
                  <div className="container">
                     <div className="row">
                        <div className="col-md-12">
                           <nav aria-label="breadcrumb">
                              <ol className="breadcrumb">
                                 <li className="breadcrumb-item"><a href="#">Categories</a></li>
                                 <li className="breadcrumb-item active" aria-current="page">CRM</li>
                              </ol>
                           </nav>
                        </div>
                     </div>
                  </div>
               </section>
               <section className="sec_filter">
                  <div className="container">
                     <div className="row">
                        <div className="col-md-12">
                           <div className="filt_all"><span className="text_fil">Filters</span><span className="number_fill">3</span> </div>
                           <div className="clear_all btn_gradint"><span className="clear_fil">Clear All</span> </div>
                        </div>
                        <div className="col-md-12">
                           <form className="form-inline">

                              <div className="form-group ">
                                 <label htmlFor="exampleFormControlSelect1"> <span className="main_name">Category</span> <span className="btn_gradint">Clear</span></label>
                                 <select className="form-control" id="exampleFormControlSelect1" style={{ color: "#000" }}>
                                    <option>CRM</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                 </select>
                              </div>
                              <div className="form-group ">
                                 <label htmlFor="exampleFormControlSelect1"> <span className="main_name">Service Provider</span>
                                    {/* <!--  */}
                                    <span className="btn_gradint">Clear</span>
                                    {/* --> */}
                                 </label>
                                 <select className="form-control" id="exampleFormControlSelect1" placeholder="Any Provider">
                                    <option >Any Provider</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                 </select>
                              </div>
                              <div className="form-group ">
                                 <label htmlFor="exampleFormControlSelect1"> <span className="main_name">Subscription</span> <span className="btn_gradint">Clear</span></label>
                                 <select className="form-control" id="exampleFormControlSelect1" style={{ color: "#000" }}>
                                    <option>Free</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                 </select>
                              </div>



                           </form>
                        </div>
                     </div>
                  </div>
               </section>
               <section className="crm_sev_sec padd_all">
                  <div className="container">
                     <div className="row">
                        <div className="col-md-12">
                           <h2 className="title_heading">CRM Plus Services <span className="icon_wrap"><i className="fa fa-pencil"></i></span></h2>
                        </div>
                        <div className="col-md-12 nav_servise">
                           <div className="top_main_tab">
                              <div className="main_lst_type pull-left">
                                 <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                    <li className="nav-item">
                                       <a className="nav-link " id="pills-new-additions-tab" data-toggle="pill" href="#pills-new-additions" role="tab" aria-controls="pills-new-additions" aria-selected="false">New Additions</a>
                                    </li>
                                    <li className="nav-item">
                                       <a className="nav-link active" id="pills-trending-tab" data-toggle="pill" href="#pills-trending" role="tab" aria-controls="pills-trending" aria-selected="true">Trending</a>
                                    </li>
                                    <li className="nav-item">
                                       <a className="nav-link" id="pills-all-tab" data-toggle="pill" href="#pills-all" role="tab" aria-controls="pills-all" aria-selected="false">All</a>
                                    </li>
                                 </ul>
                              </div>
                              <span className="pull-right">53 Search Results</span>
                              <div className="clr"></div>
                           </div>
                           <div className="tab-content" id="pills-tabContent">

                              {this.state.latestToolData.map((item, index) =>
                                 <div key={index} className="tab-pane fade show active" id="pills-trending" role="tabpanel" aria-labelledby="pills-trending-tab">
                                    <div className="cont_ser_detsil">
                                       <div className="left_part_serv">
                                          <div className="tenindin_lable">Trending</div>
                                          <div className="servi_logo"><a href="#"><img src={`${process.env.REACT_APP_IMAGE_URL}${item.logo}`} /></a></div>
                                          <div className="list_serv">

                                             <ul>
                                                {item.toolfeaturemapping.map((feature, findex) =>
                                                   <li key={findex}><a href="#"><span><img className='m-2' src="assetsde/images/Group_195.png" /></span>{feature.toolsfeaturecode0.toolsfeaturename}</a></li>
                                                )}
                                             </ul>
                                          </div>
                                       </div>
                                       <div className="right_part_serv">
                                          <div className="main_ser_name"><h2 className="pull-left">{item.toolname}</h2>
                                             <div className="review pull-right" >3.5 <span><i className="fa fa-star"></i></span><span><i className="fa fa-star"></i></span><span><i className="fa fa-star"></i></span><span><i className="fa fa-star"></i></span><span><i className="fa fa-star"></i></span> 234 Reviews</div>
                                             <div className="clr"></div>
                                          </div>
                                          <div className="list_cont">{item.description}</div>
                                          <div className="lidt_btn">
                                             <ul >
                                                <li className="btn_free"><a href="">Free Trial</a></li>
                                                <li><a href="">Visit Website <span><img src="assetsde/images/ic_redirect_to_website.png" /></span> </a></li>
                                             </ul>
                                          </div>
                                          <div className="commetb_name">
                                             <ul>
                                                <li className="_lest1">
                                                   <div className="fir_leete">A</div>
                                                   <div className="comt_g"><p><b>Ashish Behl</b></p>
                                                      <p><span>"A great remote workforce HR software"</span></p></div>
                                                </li>
                                                <li className="_lest1">
                                                   <div className="fir_leete">D</div>
                                                   <div className="comt_g"><p><b>Dhwani Gaur</b></p>
                                                      <p><span>""Amazing Experience""</span></p></div>
                                                </li>
                                             </ul>
                                          </div>
                                       </div>
                                       <div className="clr"></div>
                                    </div>



                                 </div>
                              )}
                           </div>
                           <div className="pagination_wrap">
                              <div className="lable_all pull-left"><span>1</span> <span>/ 20 Search Results</span></div>
                              <div className="pagination_tab pull-right" >
                                 <nav aria-label="Page navigation example">
                                    <ul className="pagination justify-content-end">

                                       <li className="page-item"><a className="page-link" href="#">1</a></li>
                                       <li className="page-item"><a className="page-link" href="#">2</a></li>
                                       <li className="page-item"><a className="page-link" href="#">3</a></li>
                                       <li className="page-item">
                                          <a className="page-link" href="#">Next Page <span className="arrow"><i className="fa fa-angle-right"></i></span></a>
                                       </li>
                                    </ul>
                                 </nav>
                              </div>
                              <div className="clr"></div>
                           </div>
                           <div className="load_morebtn">
                              <ul>
                                 <li><a href="">View Similar Categories</a></li>
                                 <li><a href="">View Similar Categories</a></li>
                              </ul>
                           </div>
                        </div>
                     </div>
                  </div>
               </section>



            </main>
            <DeFooter/>
         </React.Fragment>
      )
   }
}

const mapToState = state => {
   return {
      error: state.error,
      toolsId: state.toolsId
   }
}

// const mapToDispatch = dispatch => {
//    return {
//        ToolsId: (data) => dispatch(action.toolsId(data)),

//    }
// }

export default connect(mapToState, null)(SearchResult);
// export default SearchResult;