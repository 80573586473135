import { useState } from "react";
import { usePasswordValidation } from "./hooks/usePasswordValidation";
import $ from 'jquery';

function RegisterWithValidation() {

  const [password, setPassword, show, setShow, type, setType] = useState({
    firstPassword: "",
    secondPassword: "",
  });
  localStorage.setItem('firstpassowrd', password.firstPassword)
  localStorage.setItem('secondPassword', password.secondPassword)
  const [validLength, hasNumber, upperCase, lowerCase, specialChar] = usePasswordValidation({
    firstPassword: password.firstPassword,
    secondPassword: password.secondPassword,
  });

  const setFirst = (event) => {
    setPassword({ ...password, firstPassword: event.target.value });
  };
  const setSecond = (event) => {
    setPassword({ ...password, secondPassword: event.target.value });
  };

  const onHide = () => {
    $("#eye").show();
    $("#eye3").show();
    $("#eye2").hide();
    $("#eye4").hide();
    $('#password1').attr('type', 'password');
    $('#password2').attr('type', 'password');
  }

  const onShow = () => {
    $("#eye").hide();
    $("#eye3").hide();
    $("#eye2").show();
    $("#eye4").show();
    $('#password1').attr('type', 'text');
    $('#password2').attr('type', 'text');
  }

  return (
    <div className='container-fluid'>
      <div className="form-group mobile-form-group">
        <label>Create password</label>
        <div>
          <img className="pwds" src="./assets/img/ic_hide_pwd.svg" onClick={() => onShow()} id="eye" alt="" />
          <img className="pwds" src="./assets/img/ic_show_pwd.svg" id="eye2" onClick={() => onHide()} style={{ display: "none" }} alt="" />
          <input
            type="password"
            id='password1'
            name='password1'
            className="form-control"
            onChange={setFirst}
          />
        </div>
      </div><br />
      <div>
        <h6>Password Must Contain:</h6>
        <ul className='requiredconditions'>
          <div className='requiredfields1'>
            <li className="listbullet">
              {validLength ? <span style={{ color: "green" }}><i className="fa fa-check" aria-hidden="true" />Enter Valid Length</span> : <span style={{ color: "Red" }}><i className="fa fa-times" aria-hidden="true" />Enter Valid Length</span>}
            </li>
            <li className="listbullet">
              {hasNumber ? <span style={{ color: "green" }}><i className="fa fa-check" aria-hidden="true" />Has a Number</span> : <span style={{ color: "Red" }}><i className="fa fa-times" aria-hidden="true" />Has a Number</span>}
            </li>
            <li className="listbullet">
              {upperCase ? <span style={{ color: "green" }}><i className="fa fa-check" aria-hidden="true" />UpperCase</span> : <span style={{ color: "Red" }}><i className="fa fa-times" aria-hidden="true" />UpperCase</span>}
            </li>
          </div>
          <div className='requiredfields2'>
            <li className="listbullet">
              {lowerCase ? <span style={{ color: "green" }}><i className="fa fa-check" aria-hidden="true" />LowerCase</span> : <span style={{ color: "Red" }}><i className="fa fa-times" aria-hidden="true" />LowerCase</span>}
            </li>
            <li className="listbullet">
              {" "}
              {specialChar ? <span style={{ color: "green" }}><i className="fa fa-check" aria-hidden="true" />Special Character</span> : <span style={{ color: "Red" }}><i className="fa fa-times" aria-hidden="true" />Special Character</span>}
            </li>
          </div>
        </ul>
      </div>
      <br />
      <div className="form-group mobile-form-group">
        <label>Confirm password</label>
        <div>
          <img className="pwds" src="./assets/img/ic_hide_pwd.svg" onClick={() => onShow()} id="eye3" alt="" />
          <img className="pwds" src="./assets/img/ic_show_pwd.svg" onClick={() => onHide()} id="eye4" style={{ display: "none" }} alt="" />
          <input type="password"
            name='password2'
            id='password2'
            className="form-control" onChange={setSecond} />
        </div>
      </div>

    </div>
  );
}


export default RegisterWithValidation;