import React from "react";
import ReactDOM from "react-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { connect } from 'react-redux';
import * as action from '../../Store/Actions/Actions';

const TEST_SITE_KEY = process.env.REACT_APP_GOOGLE_RECAPTCHA;
const DELAY = 1500;

class Recaptcha extends React.Component {
  constructor(props, ...args) {
    super(props, ...args);
    this.state = {
      callback: "not fired",
      value: "[empty]",
      load: false,
      expired: "false"
    };
    this._reCaptchaRef = React.createRef();
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ load: true });
    }, DELAY);
    console.log("didMount - reCaptcha Ref-", this._reCaptchaRef);
  }

  handleChange = value => {
    // console.log("Captcha value:", value);
    this.setState({ value },()=>{this.props.REToken(value)});
    // if value is null recaptcha expired
    if (value === null) 
    {
      this.setState({ expired: "true" },()=>{this.props.REToken(null)})
    };
  };

  asyncScriptOnLoad = () => {
    this.setState({ callback: "called!" });
    // console.log("scriptLoad - reCaptcha Ref-", this._reCaptchaRef);
  };

  render() {
    const { value, callback, load, expired } = this.state || {};
    return (
      <div >
        {/* <h3>Recaptcha loaded callback: {callback}</h3>
        <h5>Recaptcha value: {value}</h5>
        <h5>Expired: {expired}</h5> */}
        {load && (
          <ReCAPTCHA
          
            style={{ display: "inline-block" }}
            theme="light"
            ref={this._reCaptchaRef}
            sitekey={TEST_SITE_KEY}
            onChange={this.handleChange}
            asyncScriptOnLoad={this.asyncScriptOnLoad}
          />
        )}
      </div>
    );
  }
}

const mapToState = state => {
  console.log(state)
  return {
      error: state.error

  }
}

const mapToDispatch = dispatch => {
  return {
      REToken: (data) => dispatch(action.reCaptch(data)),

  }
}

export default connect(mapToState, mapToDispatch)(Recaptcha);
// export default Recaptcha;