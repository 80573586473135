import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import * as action from '../Store/Actions/Actions';
import Token from '../Auth/Config/Config.json';

class SectorName extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sectorData: [],
        }
    }
    getSectorData = () => {
        var data = { id: -1 };
        var config = {
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}/master/1/api/getsector`,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
                'Content-Type': 'text/plain'
            },
            data: data
        };
        axios(config)
            .then(response => {
                this.setState({
                    sectorData: response.data.pd
                })
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    componentDidMount(){
        this.getSectorData();
    }
    render() {
        return (
            <React.Fragment>
                <select className='form-control'
                    name='sector'
                    ref="sector"
                    type="text"
                    placeholder="Sector"
                    onChange={(e)=>this.props.Sector(e.target.value)}
                    // onBlur={()=>this.validationEnterPrizeInfo()}
                >
                    <option>Please Select Sector</option>
                    {this.state.sectorData !== undefined ? this.state.sectorData.map((item, index) => <option key={index} value={item.sectorcode}> {item.sectorname}</option>) : null}
                </select> <br/>
                {/* <span style={{ color: "red" }}>{this.state.errors1["sector"]}</span> */}
            </React.Fragment>
        )
    }
}

const mapToState = state => {
    console.log(state)
    return {
        error: state.error,
        retoken:state.retoken
    }
}

const mapToDispatch = dispatch => {
    return {
        Sector: (data) => dispatch(action.Sector(data)),

    }
}

export default connect(mapToState, mapToDispatch)(SectorName);
// export default SectorName;
