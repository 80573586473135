import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import * as action from '../Store/Actions/Actions';
import Token from '../Auth/Config/Config.json';

class EnterpriseLocation extends Component {
    constructor(props){
        super(props);
        this.state={
            stateData:[],
            districtData:[],
            cityData:[]
        }
    }
    getStateData = () => {
        var data = { id: -1 };

        var config = {
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}/master/1/api/getstate`,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
                'Content-Type': 'text/plain'
            },
            data: data
        };

        axios(config)
            .then(response => {
                // console.log('state', response.data);
                this.setState({
                    stateData: response.data.pd
                })
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    getDistrictData = (e) => {
        // console.log(e.target.value)
        this.props.State(e.target.value)
        var data = { stateid: e.target.value };

        var config = {
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}/master/1/api/getdistrict`,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
                'Content-Type': 'text/plain'
            },
            data: data
        };

        axios(config)

            .then(response => {
                this.setState({
                    districtData: response.data.pd
                })
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    getCityData = (e) => {
        this.props.District(e.target.value)
        var data = { districtid: e.target.value };

        var config = {
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}/master/1/api/getcity`,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
                'Content-Type': 'text/plain'
            },
            data: data
        };

        axios(config)


            .then(response => {
                // console.log(response.data);
                this.setState({
                    cityData: response.data.pd
                })
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    
    componentDidMount(){
        this.getStateData();
    }
    render() {
        return (
            <div className="form-group row">
                <label htmlFor="" className="col-sm-4 col-form-label">Enterprise Location *</label>
                <div className="col-sm-7 text-left">
                    <select className='form-control'
                        name='state'
                        ref="state"
                        type="text"
                        placeholder="State"


                        onChange={(e) => this.getDistrictData(e)}
                    // value={this.state.fields1["state"]}
                    >
                        <option>Please Select</option>
                        {this.state.stateData.map((item, index) => <option key={index} value={item.statecode}>{item.statename}</option>)}

                    </select>
                    {/* <StateName /> */}
                    <br />
                    <div>
                        <select className='form-control'
                            name='district'
                            ref="district"
                            type="text"
                            placeholder="District"
                            onChange={(e) => this.getCityData(e)}

                            // value={this.state.fields1["district"]}
                        >
                            <option>Please Select</option>
                            {this.state.districtData.map((item, index) => <option key={index} value={item.districtcode}>{item.districtname}</option>)}

                        </select><br />
                        {/* <DistrictName districtInfo={this.props.state}/> */}
                        {/* <span style={{ color: "red" }}>{this.state.errors1["city"]}</span> */}
                    </div>
                    <div>
                        <select className='form-control'
                            name='city' ref="city" type="text"
                            placeholder="city"
                            onChange={(e)=>this.props.City(e.target.value)}
                            // value={this.state.fields1["city"]}
                        >
                            <option>Please Select</option>
                            {this.state.cityData.map((item, index) => <option key={index} value={item.citycode}>{item.cityname}</option>)}

                        </select>
                        {/* <CityName /> */}
                        <br />
                        {/* <span style={{ color: "red" }}>{this.state.errors1["city"]}</span> */}
                    </div>



                </div>
            </div>

        )
    }
}


const mapToState = state => {
    console.log(state)
    return {
        error: state.error,
        retoken:state.retoken
    }
}

const mapToDispatch = dispatch => {
    return {
        State: (data) => dispatch(action.State(data)),
        District:(data)=>dispatch(action.District(data)),
        City:(data)=>dispatch(action.City(data))

    }
}

export default connect(mapToState, mapToDispatch)(EnterpriseLocation);
// export default EnterpriseLocation;