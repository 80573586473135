import React from 'react';
import Token from '../../Component/Auth/Config/Config.json';
import axios from 'axios';
import { connect } from 'react-redux';
import * as action from '../../Component/Store/Actions/Actions';


class BannerLower extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			latestToolsData: [],
			trandingToolsData: [],
			trandingEServicesData: []
		}
	}
	recievLatestToolsData = () => {
		var data = { id: -1 };

		var config = {
			method: 'post',
			url: `${process.env.REACT_APP_API_URL}/digitalapi/1/api/getlatesttools`,
			headers: {
				'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
				'Content-Type': 'text/plain'
			},
			data: data
		};

		axios(config)
			.then((response) => {
				console.log(JSON.stringify(response.data));
				this.setState({
					latestToolsData: response.data.pd
				})
			})
			.catch(function (error) {
				console.log(error);
			});
	}

	recievTrandingToolsData = () => {
		var data = { id: -1 };
		var config = {
			method: 'post',
			url: `${process.env.REACT_APP_API_URL}/digitalapi/1/api/gettrendingtoolscategory`,
			headers: {
				'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
				'Content-Type': 'text/plain'
			},
			data: data
		};

		axios(config)
			.then((response) => {
				console.log(JSON.stringify(response.data));
				this.setState({
					trandingToolsData: response.data.pd
				})
			})
			.catch(function (error) {
				console.log(error);
			});
	}
	recievTrandingEservicessData = () => {
		var data = { id: -1 };

		var config = {
			method: 'post',
			url: `${process.env.REACT_APP_API_URL}/digitalapi/1/api/gettrendingtools`,
			headers: {
				'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
				'Content-Type': 'text/plain'
			},
			data: data
		};

		axios(config)
			.then((response) => {
				console.log(JSON.stringify(response.data));
				this.setState({
					trandingEServicesData: response.data.pd
				})
			})
			.catch(function (error) {
				console.log(error);
			});
	}
	componentDidMount() {
		this.recievLatestToolsData();
		this.recievTrandingToolsData();
		this.recievTrandingEservicessData();
	}
	render() {
		return (
			<React.Fragment>
				<section className="cotogry sec_padd">
					<div className="new_wrap">
						<div className="container">
							<div className="row">
								<div className="col-md-12" style={{ overflow: "hidden" }}>
									<ul className="nav nav-pills scrollmenu" id="pills-tab" role="tablist">
										<li className="nav-item">
											<a className="nav-link active" id="pills-home-tab" data-toggle="pill" href="#new-additions" role="tab" aria-controls="pills-home" aria-selected="true">New Additions</a>
										</li>
										<li className="nav-item">
											<a className="nav-link" id="pills-profile-tab" data-toggle="pill" href="#trending-categories" role="tab" aria-controls="pills-profile" aria-selected="false">Trending Categories</a>
										</li>
										<li className="nav-item">
											<a className="nav-link" id="pills-contact-tab" data-toggle="pill" href="#Trending-e-Services" role="tab" aria-controls="pills-contact" aria-selected="false">Trending e-Services</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<div className="container nav_tab_conta">
						<div className="row">
							<div className="col-md-12">
								<div className="tab-content" id="pills-tabContent">
									<div className="tab-pane fade show active" id="new-additions" role="tabpanel" aria-labelledby="pills-home-tab" style={{ paddingLeft: "0" }}>
										<div className="large-12 columns" >
											<div className="owl-carousel owl-theme" id="own_demo1" >
												{this.state.latestToolsData.map((items, category) =>
													<div className="owl-item cloned active m-2" key={category}>
														<div className="item" key={category}>
															<div className="additions" onClick={() => this.props.ToolsId(items.toolcode)}>
																<div className="inner_add">
																	<a href={'/tools-details'} style={{ color: "inherit" }}>
																		<div className="logo_tab">
																			<img src={`${process.env.REACT_APP_IMAGE_URL}${items.logo}`} alt='No-logo' />
																		</div>
																		<h4>{items.toolname}</h4>
																		<ul>
																			<li><span><i className="fa fa-star"></i></span></li>
																			<li><span><i className="fa fa-star"></i></span></li>
																			<li><span><i className="fa fa-star"></i></span></li>
																			<li><span><i className="fa fa-star"></i></span></li>
																			<li><span><i className="fa fa-star"></i></span></li>
																		</ul>
																		<div className="list_review">
																			<span>3.5 Stars (234 reviews)</span>
																		</div>
																	</a>
																</div>
															</div>
														</div>
													</div>

												)}


											</div>
										</div>

									</div>
									<div className="tab-pane fade" id="trending-categories" role="tabpanel" aria-labelledby="pills-profile-tab">
										<div className="large-12 columns">
											<div className="owl-carousel owl-theme" id="own_demo3">
												{this.state.trandingToolsData.map((item, index) =>
													<div className="owl-item cloned active m-2" key={index}>
														<div className="item" key={index}>
															<div className="additions">
																<div className="inner_add">
																	<a href="#" style={{ color: "inherit" }}>
																		<div className="logo_tab">
																			<img src={`${process.env.REACT_APP_IMAGE_URL}${item.logo}`} alt='No-logo' />
																		</div>
																		<h4>{item.toolname}</h4>
																		<ul>
																			<li><span><i className="fa fa-star"></i></span></li>
																			<li><span><i className="fa fa-star"></i></span></li>
																			<li><span><i className="fa fa-star"></i></span></li>
																			<li><span><i className="fa fa-star"></i></span></li>
																			<li><span><i className="fa fa-star"></i></span></li>
																		</ul>
																		<div className="list_review">
																			<span>3.5 Stars (234 reviews)</span>
																		</div>
																	</a>
																</div>
															</div>
														</div>
													</div>
												)}
											</div>
										</div>
									</div>

									<div className="tab-pane fade" id="Trending-e-Services" role="tabpanel" aria-labelledby="pills-contact-tab">
										<div className="large-12 columns">
											<div className="owl-carousel owl-theme" id="own_demo3">
												{this.state.trandingEServicesData.map((item, index) =>
													<div className="owl-item cloned active m-2" key={index}>
														<div className="item" key={index}>
															<div className="additions">
																<div className="inner_add">
																	<a href="#" style={{ color: "inherit" }}>
																		<div className="logo_tab"><img src={`${process.env.REACT_APP_IMAGE_URL}${item.logo}`} /> </div>
																		<h4>{item.toolname}</h4>
																		<ul>
																			<li><span><i className="fa fa-star"></i></span></li>
																			<li><span><i className="fa fa-star"></i></span></li>
																			<li><span><i className="fa fa-star"></i></span></li>
																			<li><span><i className="fa fa-star"></i></span></li>
																			<li><span><i className="fa fa-star"></i></span></li>
																		</ul>
																		<div className="list_review">
																			<span>3.5 Stars (234 reviews)</span>
																		</div>
																	</a>
																</div>
															</div>
														</div>
													</div>
												)}
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="clr"></div>
						</div>
					</div>
				</section>

			</React.Fragment>
		)
	}
}

const mapToState = state => {
	return {
		error: state.error,
	}
}

const mapToDispatch = dispatch => {
	return {
		ToolsId: (data) => dispatch(action.toolsId(data)),

	}
}

export default connect(mapToState, mapToDispatch)(BannerLower);
// export default BannerLower