import React from 'react';
import axios from 'axios';
import { Routes } from './Component/Router/Routes';
import { DeRoute } from './DigitalEservices/Router/DeRoute';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter } from 'react-router-dom';
// import '../node_modules/bootstrap/dist/css/bootstrap.min.css';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: ''
    }
  }
  componentWillMount() {
    const model = { "grant_type": "client_credentials" }
    const formBody = Object.keys(model).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(model[key])).join('&');
    const url = `${process.env.REACT_APP_API_URL}/token`;
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      'Authorization': 'Basic aFlRcDFTS2cwVFl2aVBqUTNKSXk4WDJqQnZjYToyQlBFUERPUVBsbU9mYjlPZUZKUHJEMnFnWVFh'
    }
    axios.post(url, formBody, {
      headers: headers
    })
      .then((response) => {
        console.log('auth-token', JSON.stringify(response.data.access_token));
        localStorage.setItem('access_token', JSON.stringify(response.data.access_token))
      })
      .catch((error) => {
        console.log(error);
      });

  }
  render() {
    return (
        <BrowserRouter>
          <Routes />
          <DeRoute />
        </BrowserRouter>
    );
  }

}

export default App;
