import * as actionType from '../ActionTypes/ActionTypes';

const INITIAL_STATE = {
        mobile:null,
        password:null,
        retoken:null,
        userType:null,
        state:null,
        city:null,
        district:null,
        sector:null,
        error:null,
        toolsId:null
}

export const Reducer = (state = INITIAL_STATE,action)=>{
    switch(action.type){
        case actionType.MOBILE:
            return{...state, mobile:action.payload};
        case actionType.PASSWORD: 
            return{...state, password:action.payload};   
        case actionType.RECAPTCHA:  
            return{...state, retoken:action.payload};  
        case actionType.USER_TYPE:  
            return{...state, userType:action.payload};  
        case actionType.STATE:
            return{...state, state:action.payload};
        case actionType.CITY:  
            return{...state, city:action.payload};
        case actionType.DISTRICT:  
            return{...state, district:action.payload};
        case actionType.SECTOR:  
            return{...state, sector:action.payload}; 
        case actionType.TOOLSID:  
            return{...state, toolsId:action.payload};
        default:
            return state
    }
};




export default Reducer;