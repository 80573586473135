import React from 'react';
import SliderLeftSide from '../Login/Slider_left_side';
import { connect } from 'react-redux';
import * as action from '../../Store/Actions/Actions';
import Header from '../../Header/Header';

class UserType extends React.Component {
    constructor(props){
        super(props);
        this.state={
            type:0
        }
    }
    handleSubmit=()=>{
        if(this.state.type!==0){
            this.props.User(this.state.type)
            this.props.history.push('/registration');
        }
    }
    render() {
        return (
            <React.Fragment>
                <Header/>
                      {/* <!-- Hero Banner --> */}
    <section className="sdbi-banner">
        <div className="container-fluid">
            <div className="row">
                <div className="col-sm-6 ">
                    <SliderLeftSide/>
                 </div>
                <div className="col-sm-6">
                    <div className="registration">
                        <h2>Tell us, who you are?</h2>
                        <form method="post" action=" ">
                            <div className="radio-group csradio">
                                <div className="who radio mr-4 py-2" style={{width:'100px', textAlign:'center'}} onClick={()=>this.setState({type:1})}>
                                    <img src="assetsde/images/ic_aspiring_entreprenuer.svg" alt=""/>
                                    <p>MSME's <br/>Login</p>
                                </div>
                                <div className="who radio mr-4 py-2" style={{width:'100px', textAlign:'center'}} onClick={()=>this.setState({type:2})} >
                                    <img src="assetsde/images/ic_aspiring_entreprenuer.svg" alt=""/>
                                    <p>Aspiring<br/> Entreprenuer</p>
                                </div>
                            </div>
                            <button type="button" onClick={()=>this.handleSubmit()} className="btn  login">Submit</button>
                        </form>
                    </div>
                    <p className="new-user">Already have account?<a className="register-nows" href="!#"> Back to Login</a></p>
                </div>
            </div>
        </div>
    </section>
    {/* <!-- Hero Banner --> */}
            </React.Fragment>
        )
    }
}

const mapToState = state => {
    console.log(state)
    return {
        error: state.error,
        mobile:state.mobile
  
    }
  }
  
  const mapToDispatch = dispatch => {
    return {
        User: (data) => dispatch(action.UserType(data)),
    }
  }
  
  export default connect(mapToState, mapToDispatch)(UserType);
// export default UserType;