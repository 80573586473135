import {BrowserRouter as Router,Switch,Route,Link,BrowserRouter} from "react-router-dom";
import LoginHome from "../Auth/Login/Login";
import Otp from "../Auth/Register/Registration_step_two";
import NewRegistration from "../Auth/Register/Register_Step_one";
import RegistrationA from "../Auth/Register/RegistrationA";
import Header from "../Header/Header";
import Who_are_you from "../Auth/Register/Who_are_you";
import Dashboard from "../Pages/Dashboard";

   export const Routes=()=>{
       return(
           <div>
               {/* <Header/> */}
               {/* <Header/> */}
               <Switch>
                   <Route exact path='/registration' component={RegistrationA} />
                   <Route exact path='/registration-step-one' component={NewRegistration} />
                   <Route exact path='/' component={LoginHome}/>
                   <Route exact path='/otp' component={Otp} />
                   <Route exact path='/who-are-you' component={Who_are_you} /> 
                   <Route exact path='/dashboard' component={Dashboard} /> 
                   
               </Switch>
           </div>
       )
   }