import axios from 'axios';
import React from 'react';
import Token from '../../Component/Auth/Config/Config.json';
import { connect } from 'react-redux';
import * as action from '../../Component/Store/Actions/Actions';



class CategorySection extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			categoriesData: [],
			singleCategoriesData: [],
			toolscategorycode: 4,
			catIndex: 3,
			categoryIndex: 0,
			isCRM: true,
			message: '',
			statusCode: '',
			rating: '',
			review: ''

		}
	}
	recieveCategory = () => {
		var data = { id: -1 };
		var config = {
			method: 'post',
			url: `${process.env.REACT_APP_API_URL}/digitalapi/1/api/gettoolscategory`,
			headers: {
				'Authorization': `Bearer ${localStorage.getItem('access_token')} `,
				'Content-Type': 'text/plain',
				// 'Cookie': 'PHPSESSID=gf23lkj28afmbs6vn6edb5bbr5; _csrf=7649e44f8055f8d8dbc9f3a4893b3fbb248811d803357eb8740cc2a268e903f0a%3A2%3A%7Bi%3A0%3Bs%3A5%3A%22_csrf%22%3Bi%3A1%3Bs%3A32%3A%22YcAqphRkwf_fNCjv3LTM_-LmUfT94CB8%22%3B%7D'
			},
			data: data
		};
		axios(config)
			.then((response) => {
				console.log(JSON.stringify(response.data));
				this.setState({
					categoriesData: response.data.pd
				})
			})
			.catch((error) => {
				console.log(error);
			});
	}
	recieveSingleCategory = () => {
		console.log('mai ', this.state.toolscategorycode)
		var data = { toolscategorycode: this.state.toolscategorycode };

		var config = {
			method: 'post',
			url: `${process.env.REACT_APP_API_URL}/digitalapi/1/api/getcategoryrelatedtools`,
			headers: {
				'Authorization': `Bearer  ${localStorage.getItem('access_token')}`,
				'Content-Type': 'text/plain'
			},
			data: data
		};
		axios(config)
			.then((response) => {
				console.log('single category index', JSON.stringify(response.data));
				this.setState({
					singleCategoriesData: response.data.pd,
					message: response.data.message,
					statusCode: response.data.statusCode
				})
			})
			.catch((error) => {
				console.log(error);
			});
	}
	handleSingleCategory = (toolscategorycode) => {
		console.log('mai ', this.state.toolscategorycode)
		var data = { toolscategorycode: toolscategorycode };

		var config = {
			method: 'post',
			url: `${process.env.REACT_APP_API_URL}/digitalapi/1/api/getcategoryrelatedtools`,
			headers: {
				'Authorization': `Bearer  ${localStorage.getItem('access_token')}`,
				'Content-Type': 'text/plain'
			},
			data: data
		};
		axios(config)
			.then((response) => {
				console.log('single category index', JSON.stringify(response.data));
				this.setState({
					singleCategoriesData: response.data.pd,
					toolscategorycode: toolscategorycode,
					message: response.data.message,
					statusCode: response.data.statusCode
				})
			})
			.catch((error) => {
				console.log(error);
			});
	}
	review = (id) => {
		var data = JSON.stringify({ "modulecode": id, "rrentitytypecode": 1, "rrentitycode": 3 });

		var config = {
			method: 'post',
			url: `${process.env.REACT_APP_API_URL}/commonapi/1/rating/get-overall-rating`,
			headers: {
				'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
				'Content-Type': 'application/json'
			},
			data: data
		};

		axios(config)
			.then(function (response) {
				console.log(JSON.stringify(response.data));
				this.setState({
					review: response.data.pd
				})
			})
			.catch(function (error) {
				console.log(error);
			});
		return  <span>{this.state.review}</span>
		
	}
	rating = (id) => {
		
		console.log('new id with rating', id)
		var data = JSON.stringify({ "modulecode": id, "rrentitytypecode": 1, "rrentitycode": 3 });

		var config = {
			method: 'post',
			url: `${process.env.REACT_APP_API_URL}/commonapi/1/rating/get-overall-rating`,
			headers: {
				'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		
		axios(config)
			.then( (response) =>{
				
				console.log(JSON.stringify(response.data));
				this.setState({
					rating: response.data.pd
				})
				
				return 
			})
			.catch(function (error) {
				console.log(error);
			});

	}

	componentDidMount() {
		this.recieveCategory();
		this.recieveSingleCategory();
	}
	render() {
		return (
			<React.Fragment>
				<section className="horizalt_catg">
					<div className="container" style={{ padding: "0" }}>
						<div className="hor_inner">
							<div className="row">
								<div className="col-3">
									<h5 className="title_menu">All <b> Categories</b></h5>
									<div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
										{/* <!-- <a className="nav-link firt_tab" id="all-categories-tab" data-toggle="pill" href="#all-categories" role="tab" aria-controls="all-categories" aria-selected="false"> </a> --> */}

										{/* <a className="nav-link active" id="crm-tab" data-toggle="pill" href="#crm" role="tab" aria-controls="crm" aria-selected="true">CRM</a> */}

										{this.state.categoriesData.map((item, index) =>
											<a
												key={index}
												className={`nav-link acitve`}
												id={item.toolscategoryname + '-tab'}
												data-toggle="pill"
												href={'#' + item.toolscategoryname}
												role="tab"
												aria-controls={item.toolscategorycode}
												aria-selected={`${this.state.categoryIndex === index ? 'true' : 'false'}`}
												onClick={() => this.handleSingleCategory(item.toolscategorycode)}
											>
												{item.toolscategoryname}
											</a>
										)}
										{/* <a className="nav-link" id="digital-payments-transactions-tab" data-toggle="pill" href="#digital-payments-transactions" role="tab" aria-controls="digital-payments-transactions" aria-selected="false">Digital Payments & Transactions</a>
								      <a className="nav-link" id="business-growth-tab" data-toggle="pill" href="#business-growt" role="tab" aria-controls="business-growt" aria-selected="false">Business Growth</a>
								      <a className="nav-link" id="accounting-tools-tab" data-toggle="pill" href="#accounting-tools" role="tab" aria-controls="accounting-tools" aria-selected="false">Accounting Tools</a>
								      <a className="nav-link" id="legal-services-tab" data-toggle="pill" href="#legal-services" role="tab" aria-controls="legal-services" aria-selected="false">Legal Services</a>
								      <a className="nav-link" id="marketing-services-tab" data-toggle="pill" href="#marketing-services" role="tab" aria-controls="marketing-services" aria-selected="false">Marketing Services</a>
								      <a className="nav-link" id="tax-advisory-tab" data-toggle="pill" href="#tax-advisory" role="tab" aria-controls="tax-advisory" aria-selected="false">Tax Advisory</a> */}
									</div>
								</div>
								<div className="col-9">
									<div className="tab-content" id="v-pills-tabContent">

										<div className="tab-pane fade " id="all-categories" role="tabpanel" aria-labelledby="all-categories-tab">Sandeep</div>
										<div className="tab-pane fade show active" id={this.state.toolscategorycode + '-tab'} role="tabpanel" aria-labelledby={this.state.toolscategorycode + '-tab'}>
											<div className="row">
												{this.state.statusCode === 'S006' ? this.state.singleCategoriesData.map((item, index) =>
													<div className="col-md-4" key={index}>
														{console.log(item.toolname)}
														<div className="additions tab_hot">
															<div className="inner_add">
																<a href="/tools-details" style={{ color: "inherit" }}>
																	<div className="logo_tab"><img src="assetsde/images/1.png" /> </div>
																	<h4>{item.toolname}</h4>
																	<ul>

																		<li><span><i className="fa fa-star"></i></span></li>
																		<li><span><i className="fa fa-star"></i></span></li>
																		<li><span><i className="fa fa-star"></i></span></li>
																		<li><span><i className="fa fa-star"></i></span></li>
																		<li><span><i className="fa fa-star"></i></span></li>
																	</ul>
																	<div className="list_review">
																	{/* {this.rating(item.toolscategorycode)} <span>Stars (234 reviews)</span> */}
																	</div>
																</a>
															</div>
														</div>
													</div>
												) : this.state.message}
											</div>
										</div>
										<div className="tab-pane fade" id="payroll-management" role="tabpanel" aria-labelledby="payroll-management-tab">Lorem Ipsum is simply dummy</div>
										<div className="tab-pane fade" id="digital-payments-transactions" role="tabpanel" aria-labelledby="digital-payments-transactions-tab">Lorem Ipsum is simply dummy</div>
										<div className="tab-pane fade" id="business-growt" role="tabpanel" aria-labelledby="business-growth-tab">Lorem Ipsum is simply dummy</div>
										<div className="tab-pane fade" id="accounting-tools" role="tabpanel" aria-labelledby="accounting-tools-tab">Lorem Ipsum is simply dummy</div>
										<div className="tab-pane fade" id="legal-services" role="tabpanel" aria-labelledby="legal-services-tab">Lorem Ipsum is simply dummy</div>
										<div className="tab-pane fade" id="marketing-services" role="tabpanel" aria-labelledby="legal-services-tab">Lorem Ipsum is simply dummy</div>
										<div className="tab-pane fade" id="tax-advisory" role="tabpanel" aria-labelledby="tax-advisory-tab">Lorem Ipsum is simply dummy</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</React.Fragment>
		)
	}
}


const mapToState = state => {
	return {
		error: state.error,
		tools: state.tools
	}
}

const mapToDispatch = dispatch => {
	return {
		ToolsId: (data) => dispatch(action.toolsId(data)),
	}
}

export default connect(mapToState, mapToDispatch)(CategorySection);
// export default CategorySection;