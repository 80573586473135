
export const MOBILE = "MOBILE";
export const PASSWORD = "PASSWORD";
export const RECAPTCHA='RECAPTCHA';
export const USER_TYPE='USER_TYPE';
export const STATE='STATE';
export const CITY='CITY';
export const DISTRICT_LIST='DISTRICT_LIST';
export const DISTRICT='DISTRICT';
export const SECTOR='SECTOR';
export const TOOLSID='TOOLSID';