import React from 'react';



class CircleStats extends React.Component {
    render(){
        return (
            <React.Fragment>
                <section className="counter sec_padd">
            <div className="container"> 
                 <div className="row">
                  <div className="col-md-4">
                     <div className="imd_wraper war1">
                        <img src="assetsde/images/cr1.png"/>
                        <div className="inr_count">
                           <p><b>100% + </b></p>
                           <p>Digital Solutions</p>
                        </div>
                        
                     </div>
                  </div>
                  <div className="col-md-4">
                     <div className="imd_wraper war1">
                        <img src="assetsde/images/cr2.png"/>
                        <div className="inr_count">
                           <p><b>20 + </b></p>
                           <p>Enterprise Domains</p>
                        </div>
                        
                     </div>
                  </div>
                  <div className="col-md-4">
                     <div className="imd_wraper war1">
                        <img src="assetsde/images/cr3.png"/>
                        <div className="inr_count">
                           <p><b>20 + </b></p>
                           <p>Partner</p>
                        </div>
                        
                     </div>
                  </div>
                 </div>
            </div>
         </section>

            </React.Fragment>
        )
    }
}

export default CircleStats;