import React from 'react';
class DigitalHeader extends React.Component {
   render() {
      return (
         <React.Fragment>
            <header>
               <div className="top_header clearfix">
                  <div className="container">
                     <div className="row">
                        <div className="col-md-2">
                           <div className="logo">
                              <a href="#">
                                 <img src="assetsde/images/logo.png" />
                              </a>
                           </div>
                        </div>
                        <div className="col-md-4">
                           <div className="serch_bar">
                              <form className="my-2 my-md-0">
                                 <input className="form-control" type="text" placeholder="Search in degital resources" />
                                 <i className="fa fa-search"></i>
                              </form>
                           </div>
                        </div>
                        <div className="col-md-6">
                           <div className="list_wrap">
                              <div className="rate width-16 pull-left">
                                 {/* <!-- Product #1 --> */}
                                 <div className="number">
                                    <span className="minus">A-</span>
                                    <input type="text" value="100%" readOnly />
                                    <span className="plus">A+</span>
                                 </div>
                              </div>
                              <div className="top_nav pull-left lan_wrap">
                                 <nav className="navbar navbar-expand-lg">
                                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                       <ul className="navbar-nav ">
                                          <li className="nav-item dropdown">
                                             <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                English
                                    </a>
                                             <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                <a className="dropdown-item" href="#">Japan</a>
                                                <a className="dropdown-item" href="#">Nepali</a>
                                             </div>
                                          </li>
                                          <li className="nav-item">
                                             <a className="nav-link" href="#">Contact</a>
                                          </li>
                                       </ul>
                                    </div>
                                 </nav>
                              </div>
                              <div className="social_link pull-right">
                                 <nav className="navbar navbar-expand-lg navbar-light">
                                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                       <ul className="navbar-nav">
                                          <li className="nav-item"> <a className="nav-link" href="#"><img src="assetsde/images/fb.png" /></a></li>
                                          <li className="nav-item"> <a className="nav-link" href="#"><img src="assetsde/images/twitter.png" /></a></li>
                                          <li className="nav-item"> <a className="nav-link" href="#"><img src="assetsde/images/youtube.png" /></a></li>
                                          <li className="nav-item"> <a className="nav-link" href="#"><img src="assetsde/images/insta.png" /></a></li>
                                          <li className="nav-item"> <a className="nav-link" href="#"><img src="assetsde/images/linkdin.png" /></a></li>
                                       </ul>
                                    </div>
                                 </nav>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="bootom_header ">
                  <div className="container">
                     <div className="row">
                        <div className="col-md-12">
                           <nav className="navbar navbar-expand-lg  ">
                              <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#main_nav" aria-expanded="false" aria-label="Toggle navigation">
                                 <span className="navbar-toggler-icon"><i className="fa fa-bars"></i></span>
                              </button>
                              <div className="collapse navbar-collapse" id="main_nav">
                                 <ul className="navbar-nav">
                                    <li className="nav-item ">
                                       <a className="nav-link" href="#">ABOUT US </a>
                                    </li>
                                    <li className="nav-item">
                                       <a className="nav-link" href="#">SERVICES <span><i className="fa fa-angle-right"></i></span></a>
                                    </li>
                                    <li className="nav-item ser_set" >
                                       <a className="nav-link" href="#"><span className="seting_icon"><i className="fa fa-sun-o"></i></span> DIGITAL e-Services <span><i className="fa fa-angle-right"></i></span></a>
                                    </li>
                                    <li className="nav-item">
                                       <a className="nav-link active" href="#">Home </a>
                                    </li>
                                    <li className="nav-item dropdown has-megamenu">
                                       <a className="nav-link " href="#" data-toggle="dropdown"> Categories  </a>
                                       <div className="dropdown-menu megamenu" role="menu" style={{ marginLeft: "317px" }}>
                                          <div className="container">
                                             <div className="row">
                                                <div className="col-md-3">
                                                   <div className="col-megamenu">
                                                      <h6 className="title">CRM</h6>
                                                      <ul className="list-unstyled">
                                                         <li><a href="#">Zoho CRM Plus </a></li>
                                                         <li><a href="#">Microsoft Azure CRM </a></li>
                                                         <li><a href="#">Salesforce </a></li>
                                                         <li><a href="#">Hubspot </a></li>
                                                         <li><a href="#">Stackby</a></li>
                                                      </ul>
                                                   </div>
                                                   {/* <!-- col-megamenu.// --> */}
                                                </div>
                                                {/* <!-- end col-3 --> */}
                                                <div className="col-md-3">
                                                   <div className="col-megamenu">
                                                      <h6 className="title">Payroll Management</h6>
                                                      <ul className="list-unstyled">
                                                         <li><a href="#">Microsoft Azure Cost </a></li>
                                                         <li><a href="#">Management and Billing</a></li>
                                                         <li><a href="#">Opfin Razorpay</a></li>
                                                         <li><a href="#">Kredily </a></li>
                                                         <li><a href="#">Zing HR</a></li>
                                                      </ul>
                                                   </div>
                                                   {/* <!-- col-megamenu.// --> */}
                                                </div>
                                                {/* <!-- end col-3 --> */}
                                                <div className="col-md-3">
                                                   <div className="col-megamenu">
                                                      <h6 className="title">Digital Payments & Transactions</h6>
                                                      <ul className="list-unstyled">
                                                         <li><a href="#">Zoho </a></li>
                                                         <li><a href="#">PayU </a></li>
                                                         <li><a href="#">Instamojo </a></li>
                                                         <li><a href="#">Razorpay</a></li>
                                                      </ul>
                                                   </div>
                                                   {/* <!-- col-megamenu.// --> */}
                                                </div>
                                                <div className="col-md-3">
                                                   <div className="col-megamenu">
                                                      <h6 className="title">Business Growth</h6>
                                                      <ul className="list-unstyled">
                                                         <li><a href="#">Meesho </a></li>
                                                         <li><a href="#">Udaan</a></li>
                                                         <li><a href="#">MSMEmart</a></li>
                                                      </ul>
                                                   </div>
                                                   {/* <!-- col-megamenu.// --> */}
                                                </div>
                                                {/* <!-- end col-3 --> */}
                                                <div className="col-md-3">
                                                   <div className="col-megamenu">
                                                      <h6 className="title">Accounting Tools</h6>
                                                      <ul className="list-unstyled">
                                                         <li><a href="#">Zoho</a></li>
                                                         <li><a href="#">SAP Business One </a></li>
                                                         <li><a href="#">Tally </a></li>
                                                         <li><a href="#">Khatabook</a></li>

                                                      </ul>
                                                   </div>
                                                   {/* <!-- col-megamenu.// --> */}
                                                </div>
                                                {/* <!-- end col-3 --> */}
                                                <div className="col-md-3">
                                                   <div className="col-megamenu">
                                                      <h6 className="title">Marketing Services</h6>
                                                      <ul className="list-unstyled">
                                                         <li><a href="#">MMarketo </a></li>
                                                         <li><a href="#">Zendesk </a></li>
                                                         <li><a href="#">Hubspot</a></li>
                                                         <li><a href="#">Custom Menu</a></li>
                                                         <li><a href="#">Custom Menu</a></li>
                                                         <li><a href="#">Custom Menu</a></li>
                                                      </ul>
                                                   </div>
                                                   {/* <!-- col-megamenu.// --> */}
                                                </div>
                                                {/* <!-- end col-3 --> */}
                                                <div className="col-md-3">
                                                   <div className="col-megamenu">
                                                      <h6 className="title">Tax Advisory</h6>
                                                      <ul className="list-unstyled">
                                                         <li><a href="#">Cleartax </a></li>
                                                         <li><a href="#">EY Tax Advisory tool</a></li>

                                                      </ul>
                                                   </div>
                                                   {/* <!-- col-megamenu.// --> */}
                                                </div>
                                                <div className="col-md-3">
                                                   <div className="col-megamenu">
                                                      <h6 className="title">Legal Services</h6>
                                                      <ul className="list-unstyled">
                                                         <li><a href="#">Vakilsearch </a></li>
                                                         <li><a href="#">Indiafilings</a></li>
                                                      </ul>
                                                   </div>
                                                   {/* <!-- col-megamenu.// --> */}
                                                </div>
                                                {/* <!-- end col-3 --> */}
                                             </div>
                                          </div>
                                          {/* <!-- end row -->  */}
                                       </div>
                                       {/* <!-- dropdown-mega-menu.// --> */}
                                    </li>
                                 </ul>
                                 <ul className="navbar-nav ml-auto">
                                    <li className="nav-item dropdown btn_log-in">
                                       <a className="nav-link  dropdown-toggle" href="#" data-toggle="dropdown"> LOGIN AS </a>
                                       <ul className="dropdown-menu dropdown-menu-right">
                                          <li><a className="dropdown-item" href="#"> Admin</a></li>
                                          <li><a className="dropdown-item" href="#"> User </a></li>
                                       </ul>
                                    </li>
                                    <li className="nav-item nav_take"><a className="nav-link" href="#"> TAKE A TOUR </a></li>
                                 </ul>
                              </div>
                              {/* <!-- navbar-collapse.// --> */}
                           </nav>
                        </div>
                     </div>
                  </div>
               </div>
            </header>
         </React.Fragment>
      )
   }
}

export default DigitalHeader;