import React from 'react';



class DeFooter extends React.Component {
    render() {
        return (
            <React.Fragment>
                 <footer>
      	<div className="container">
      		<div className="row">
      			<div className="col-md-12">
      				<a href="#">
      						<img src="assetsde/images/footer_logo.png"/>
      					</a>
      			</div>
      			<div className="col-md-3">
      				<div className="logo_footer">
      					
      					<p>Publication And Reports Multilateral Agencies Reservation Roster Customer Care</p>
      				</div>
      			</div>
      			<div className="col-md-3">
      				<div className="menu_footer">
      				<ul>
      					<li><a href="#">Feedback</a></li>
      					<li><a href="#">Retiree Portal</a></li>
      					<li><a href="#"> RTI Cell  </a></li>
      					<li><a href="#">Circulars</a></li>
      					<li><a href="#">Tenders</a></li>
      				</ul>
      				</div>
      			</div>
      			<div className="col-md-2"></div>
      			<div className="col-md-4">
      				 <div className="cont_list">
      				 	<p className="tile_lisr ing_inline"> Contact</p>
      				 	<div className="social_link ing_inline">
                        <nav className="navbar navbar-expand-lg navbar-light">
                           <div className="collapse navbar-collapse" id="navbarSupportedContent">
                              <ul className="navbar-nav">
                                 
                                 <li className="nav-item"> <a className="nav-link" href="#"><img src="assetsde/images/fb.png"/></a></li>
                                 <li className="nav-item"> <a className="nav-link" href="#"><img src="assetsde/images/twitter.png"/></a></li>
                                 <li className="nav-item"> <a className="nav-link" href="#"><img src="assetsde/images/youtube.png"/></a></li>
                                 <li className="nav-item"> <a className="nav-link" href="#"><img src="assetsde/images/insta.png"/></a></li>
                                 <li className="nav-item"> <a className="nav-link" href="#"><img src="assetsde/images/linkdin.png"/></a></li>
                              </ul>
                           </div>
                        </nav>
                     </div>
                     <div className="app_wrap ">
                     	<p className="title"> Download the app on</p>
                        <nav className="navbar navbar-expand-lg navbar-light">
                           <div className="collapse navbar-collapse" id="navbarSupportedContent">

                              <ul className="navbar-nav">
                                 
                                 <li className="nav-item"> <a className="nav-link" href="#"><img src="assetsde/images/apple.png"/></a></li>
                                 <li className="nav-item"> <a className="nav-link" href="#"><img src="assetsde/images/google.png"/></a></li>
                                 
                              </ul>
                           </div>
                        </nav>
                     </div>
      				 </div>

      			</div>
      		</div>
      	</div>
      </footer>
            </React.Fragment>
        )
    }
}

export default DeFooter;