export const CONFIG = {
    "TOKEN_ENDPOINT": `https://wso2.mycitycafe.com:9443/oauth2/token`,
    "AUTHORIZE_ENDPOINT": `https://wso2.mycitycafe.com:9443/oauth2/authorize`,
    "RESPONSE_TYPE": "code",
    "SCOPE": "openid",
    "REDIRECT_URI": `https://sidbi.jithvar.com/`,
    "CLIENT_ID": "bIgj3rXc9a_PZrEFOVuOjqKjRxIa",
    "CLIENT_SECRET": "eVqu7LwmxBKRH9c3KhVMZFnKeOQa",
    "GRANT_TYPE": "authorization_code",
    "CLIENT_URL": "https://mycitycafe.com/login/dashboard",
    "LOGOUT_URL": `https://wso2.mycitycafe.com:9443/oidc/logout`,
    "COOKIE_PATH": "/"
};