import React from 'react';


class BannerSection extends React.Component {
    render() {
        return(
            <React.Fragment>
        <section className="main_banner_sec">
  		<div className="large-12 columns">
          <div className="owl-carousel owl-theme" id="owl-carousel1">
            <div className="item banner_sec">
              <div className="container">
      			<div className="rwo">
      				<div className="col-md-12">
      					<div className="banner_wrap">
      					     <h2 style={{color:"#ffffff"}}>Grow your business operations</h2>
      					     <p>through our partnership with distinguished pool of digital service providers to leverage tools/ software to support you in your digitisation journey in the areas of CRM, Accounting, Payroll, HR, Cloud, Marketing and many more...</p>
      					     <p className="btn_desin"><a href="#">Register Now</a></p>
      					</div>
      				</div>
      			</div>
      		</div>
            </div>
              <div className="item banner_sec">
              <div className="container">
      			<div className="rwo">
      				<div className="col-md-12">
      					<div className="banner_wrap">
      					     <h2 style={{color:"#ffffff"}}>Grow your business operations</h2>
      					     <p>through our partnership with distinguished pool of digital service providers to leverage tools/ software to support you in your digitisation journey in the areas of CRM, Accounting, Payroll, HR, Cloud, Marketing and many more...</p>
      					     <p className="btn_desin"><a href="#">Register Now</a></p>
      					</div>
      				</div>
      			</div>
      		</div>
            </div>
              <div className="item banner_sec">
              <div className="container">
      			<div className="rwo">
      				<div className="col-md-12">
      					<div className="banner_wrap">
      					     <h2 style={{color:"#ffffff"}}>Grow your business operations</h2>
      					     <p>through our partnership with distinguished pool of digital service providers to leverage tools/ software to support you in your digitisation journey in the areas of CRM, Accounting, Payroll, HR, Cloud, Marketing and many more...</p>
      					     <p className="btn_desin"><a href="#">Register Now</a></p>
      					</div>
      				</div>
      			</div>
      		</div>
            </div>
            
          </div>
          <div className="inner_efect">
      			 <div className="container">
      			 	<div className="row">
      			 		<div className="col-md-3">
      			 			<div className="effect-1">
      			 				<div className="fev"><i><img src="assetsde/images/ic_digital r_performance.png"/></i> <span>Efficient<br/> Performance</span></div>
      			 			</div>
      			 		</div>
      			 		<div className="col-md-3">
      			 			<div className="effect-1">
      			 				<div className="fev"><i><img src="assetsde/images/ic_digita r_productivity.png"/></i> <span>Enhanced <br/> productivity</span></div>
      			 			</div>
      			 		</div>
      			 		<div className="col-md-3">
      			 			<div className="effect-1">
      			 				<div className="fev"><i><img src="assetsde/images/ic_digital r_connectivity.png"/></i> <span>Increased <br/> connectivity</span></div>
      			 			</div>
      			 		</div>
      			 		<div className="col-md-3">
      			 			<div className="effect-1">
      			 				<div className="fev"><i><img src="assetsde/images/ic_digita r_management.png"/></i> <span>Ease in <br/> management</span></div>
      			 			</div>
      			 		</div>
      			 	</div>
      			 </div>
      		</div>
             
        </div>
      	</section>

		
      	{/* <section className="cotogry sec_padd">
            <div className="new_wrap">
               <div className="container">
                  <div className="row">
                     <div className="col-md-12" style={{overflow: "hidden"}}>
                       
                        <ul className="nav nav-pills scrollmenu" id="pills-tab" role="tablist">
                          <li className="nav-item">
                            <a className="nav-link active" id="pills-home-tab" data-toggle="pill" href="#new-additions" role="tab" aria-controls="pills-home" aria-selected="true">New Additions</a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link" id="pills-profile-tab" data-toggle="pill" href="#trending-categories" role="tab" aria-controls="pills-profile" aria-selected="false">Trending Categories</a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link" id="pills-contact-tab" data-toggle="pill" href="#Trending-e-Services" role="tab" aria-controls="pills-contact" aria-selected="false">Trending e-Services</a>
                          </li>
                         
                        </ul>


                     </div>
                  </div>
               </div>
            </div>
             <div className="container nav_tab_conta">
             	<div className="row">
             		<div className="col-md-12">
             			
						<div className="tab-content" id="pills-tabContent">
						  <div className="tab-pane fade show active" id="new-additions" role="tabpanel" aria-labelledby="pills-home-tab" style={{paddingLeft: "0"}}>
						  	 <div className="large-12 columns" >
						          <div className="owl-carousel owl-theme" id="own_demo1" >
						            <div className="item">
						                <div className="additions">
						                	<div className="inner_add">
						                		<a href="#" style={{color: "inherit"}}>
						                		<div className="logo_tab"><img src="assetde/images/1.png"/> </div>
						                		<h4>Zoho CRM Plus</h4>
						                		<ul>
						                			<li><span><i className="fa fa-star"></i></span></li>
						                			<li><span><i className="fa fa-star"></i></span></li>
						                			<li><span><i className="fa fa-star"></i></span></li>
						                			<li><span><i className="fa fa-star"></i></span></li>
						                			<li><span><i className="fa fa-star"></i></span></li>
						                		</ul>
						                		<div className="list_review">
						                			<span>3.5 Stars (234 reviews)</span>
						                		</div>
						                	</a>
						                	</div>
						                </div>
						            </div>
						            <div className="item">
						              <div className="additions">
						                	<div className="inner_add">
						                		<a href="#" style={{color: "inherit"}}>
						                		<div className="logo_tab"><img src="assetde/images/2.png"/> </div>
						                		<h4>Microsoft Azure CRM</h4>
						                		<ul>
						                			<li><span><i className="fa fa-star"></i></span></li>
						                			<li><span><i className="fa fa-star"></i></span></li>
						                			<li><span><i className="fa fa-star"></i></span></li>
						                			<li><span><i className="fa fa-star"></i></span></li>
						                			<li><span><i className="fa fa-star"></i></span></li>
						                		</ul>
						                		<div className="list_review">
						                			<span>3.5 Stars (234 reviews)</span>
						                		</div>
						                	</a>
						                	</div>
						                </div>
						            </div>
						            <div className="item">
						              <div className="additions">
						                	<div className="inner_add">
						                		<a href="#" style={{color: "inherit"}}>
						                		<div className="logo_tab"><img src="aasetde/images/3.png"/> </div>
						                		<h4>Salesforce</h4>
						                		<ul>
						                			<li><span><i className="fa fa-star"></i></span></li>
						                			<li><span><i className="fa fa-star"></i></span></li>
						                			<li><span><i className="fa fa-star"></i></span></li>
						                			<li><span><i className="fa fa-star"></i></span></li>
						                			<li><span><i className="fa fa-star"></i></span></li>
						                		</ul>
						                		<div className="list_review">
						                			<span>3.5 Stars (234 reviews)</span>
						                		</div>
						                	</a>
						                	</div>
						                </div>
						            </div>
						            <div className="item">
						              <div className="additions">
						                	<div className="inner_add">
						                		<a href="#" style={{color: "inherit"}}>
						                		<div className="logo_tab"><img src="assetsde/images/4.png"/> </div>
						                		<h4>Salesforce</h4>
						                		<ul>
						                			<li><span><i className="fa fa-star"></i></span></li>
						                			<li><span><i className="fa fa-star"></i></span></li>
						                			<li><span><i className="fa fa-star"></i></span></li>
						                			<li><span><i className="fa fa-star"></i></span></li>
						                			<li><span><i className="fa fa-star"></i></span></li>
						                		</ul>
						                		<div className="list_review">
						                			<span>3.5 Stars (234 reviews)</span>
						                		</div>
						                	</a>
						                	</div>
						                </div>
						            </div>
						         	
						            <div className="item">
							             <div className="additions">
							                	<div className="inner_add">
							                		<a href="#" style={{color: "inherit"}}>
							                		<div className="logo_tab"><img src="assetde/images/5.png"/> </div>
							                		<h4>Zoho CRM Plus</h4>
							                		<ul>
							                			<li><span><i className="fa fa-star"></i></span></li>
							                			<li><span><i className="fa fa-star"></i></span></li>
							                			<li><span><i className="fa fa-star"></i></span></li>
							                			<li><span><i className="fa fa-star"></i></span></li>
							                			<li><span><i className="fa fa-star"></i></span></li>
							                		</ul>
							                		<div className="list_review">
							                			<span>3.5 Stars (234 reviews)</span>
							                		</div>
							                	</a>
							                	</div>
							                </div>
						            </div>
						            <div className="item">
						              <div className="additions">
						                	<div className="inner_add">
						                		<a href="#" style={{color: "inherit"}}>
						                		<div className="logo_tab"><img src="assetde/images/6.png"/> </div>
						                		<h4>Zoho CRM Plus</h4>
						                		<ul>
						                			<li><span><i className="fa fa-star"></i></span></li>
						                			<li><span><i className="fa fa-star"></i></span></li>
						                			<li><span><i className="fa fa-star"></i></span></li>
						                			<li><span><i className="fa fa-star"></i></span></li>
						                			<li><span><i className="fa fa-star"></i></span></li>
						                		</ul>
						                		<div className="list_review">
						                			<span>3.5 Stars (234 reviews)</span>
						                		</div>
						                	</a>
						                	</div>
						                </div>
						            </div>
						           
						      
						          </div>
                              </div>
						  </div>
						  <div className="tab-pane fade" id="trending-categories" role="tabpanel" aria-labelledby="pills-profile-tab">
						  	<div className="large-12 columns">
						          <div className="owl-carousel owl-theme" id="own_demo2">
						         
						            <div className="item">
						                	<div className="inner_add_red ">
						                		<a href="#" className="gradin1">Cloud Services</a>
						                	</div>
						            </div>
						            <div className="item">
						                	<div className="inner_add_red ">
						                		<a href="#" className="gradin2">Legal Services</a>
						                	</div>
						            </div>
						            <div className="item">
						                	<div className="inner_add_red ">
						                		<a href="#" className="gradin3">Business Growth</a>
						                	</div>
						            </div>
						            <div className="item">
						                	<div className="inner_add_red">
						                		<a href="#" className="gradin4">Accounting Tools</a>
						                	</div>
						            </div>
						            <div className="item">
						                	<div className="inner_add_red ">
						                		<a href="#" className="gradin5">Marketing Services</a>
						                	</div>
						            </div>
						             <div className="item">
						                	<div className="inner_add_red ">
						                		<a href="#" className="gradin6">Tax Advisory</a>
						                	</div>
						            </div>
						            
						           
						      
						          </div>
                              </div>

						  </div>
						  <div className="tab-pane fade" id="Trending-e-Services" role="tabpanel" aria-labelledby="pills-contact-tab">Lorem Ipsum is simply dummy</div>
						</div>
             		</div>
             	</div>
             </div>
             <div className="clr"></div>
      	</section> */}
            </React.Fragment>
        )
    }
}

export default BannerSection;