import React, { Component } from 'react';

class Header extends Component {
  render() {
    return (
      <React.Fragment>
        <header className="site-header">
          <div className="container">

            <div className="sidbi-header">
              <nav className="navbar navbar-expand-lg navbar-light">
                <a className="navbar-brand" href="/"><img src="assetsde/images/logo.png" alt="" /></a>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                  <form className="form-inline my-2 my-lg-0 custom_form">
                    <select className="selectpicker" data-show-subtext="true" data-live-search="true">
                      <option data-subtext="Demo-one">All</option>
                      <option data-subtext="Demo-one">01</option>
                      <option data-subtext="Demo-one">02</option>
                      <option data-subtext="Demo-one">03</option>
                      <option data-subtext="Demo-one">04</option>

                    </select>
                    <div className="input-group">
                      <input type="text" className="form-control" placeholder="Search all resources" />
                      <div className="input-group-append">
                        <button className="btn btn-secondary search_secondry" type="button">
                          <i className="fa fa-search"></i>
                        </button>
                      </div>
                    </div>
                  </form>
                  <ul className="zoom">
                    <li><button className="minus">A-</button></li>
                    <li><p id="per">100%</p></li>
                    <li><button className="plus">A+</button> </li>
                  </ul>
                  <ul className="navbar-nav mr-auto ">
                    <li className="nav-item dropdown bordr-rgt">
                      <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        English
                </a>
                      <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                        <a className="dropdown-item" href="#">Action</a>
                        <a className="dropdown-item" href="#">Another action</a>
                        <a className="dropdown-item" href="#">Something else here</a>
                      </div>
                    </li>
                    <li className="nav-item bordr-rgt">
                      <a className="nav-link" href="#">Contact</a>
                    </li>

                  </ul>
                  <ul className="navbar_icon navbar-nav ml-auto nav-flex-icons">
                    <li className="nav-item">
                      <a className="facebook" href="#"><img src="assetsde/images/ic_facebook_logo.svg" alt="" /></a>
                    </li>
                    <li className="nav-item">
                      <a className="facebook" href="#"><img src="assetsde/images/ic_twitter_logo.svg" alt="" /></a>
                    </li>
                    <li className="nav-item">
                      <a className="facebook" href="#"><img src="assetsde/images/ic_youtube_logo.svg" alt="" /></a>
                    </li>
                    <li className="nav-item">
                      <a className="facebook" href="#"><img src="assetsde/images/ic_instagram_logo.svg" alt="" /></a>
                    </li>
                    <li className="nav-item">
                      <a className="facebook" href="#"><img src="assetsde/images/ic_linkedin_logo.svg" alt="" /></a>
                    </li>
                  </ul>
                </div>
              </nav>

            </div>
            <section id="header-bar">
              <div className="topnav">
                <ul className="navbar-nav mr-auto align-items-center">
                  <li><a className="bordr-rgt" href="#"> ABOUT US</a></li>
                  <li className="nav-item dropdown">
                    <a className="nav-link dropdown-toggle " href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      SERVICES
                    </a>
                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                      <a className="dropdown-item" href="#">Action</a>
                      <a className="dropdown-item" href="#">Another action</a>
                      <a className="dropdown-item" href="#">Something else here</a>
                    </div>
                  </li>


                </ul>

                <ul className="right_section">
                  <li>
                    <button type="button" style={{
                      backgroundColor: '#000',
                      fontSize: '15px',
                      color: '#fff',
                      fontFamily: 'poppinssemibold',
                      borderRadius: '5px',
                      paddingTop: '7px',
                      paddingBottom: '7px',
                      paddingLeft: '3px',
                      paddingRight: '3px'
                    }} className="search_secondry1 dropdown-toggle" data-toggle="dropdown">
                      LOGIN AS
                            </button>
                    <div className="dropdown-menu">
                      <div className="card">

                        <div className="row">
                          <div className="col-sm-6">
                            <div className="loginas" onClick={() => window.location.href = '/'}>
                              <img src="assetsde/images/ic_MSME.svg" className="img-responsive" />
                              <p>MSME</p>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="loginas" onClick={() => window.location.href = '/'}>
                              <img src="assetsde/images/ic_aspiring_entreprenuer.svg" className="img-responsive" />
                              <p>Aspiring Entrepreneur</p>
                            </div>
                          </div>
                          <hr />
                          <a href='/registration-step-one' className='ml-4 my-4 py-2 px-2'>Register Now</a>
                        </div>
                      </div>
                    </div>
                  </li>
                  {/* <li>
            {localStorage.getItem('username')===null || undefined ?
            <a href="/login">
             Login as
            </a>:
            <a href="#">Welcome {localStorage.getItem('username')} !</a>}
            
          </li> */}
                  <li><a href="#"> TAKE A TOUR</a></li>

                </ul>

              </div>
            </section>
          </div>
        </header>
      </React.Fragment>
    )
  }
}

export default Header;