import * as actionsType from '../ActionTypes/ActionTypes';
import axios from 'axios';
import Token from '../../Auth/Config/Config.json'


// Mobile
export function mobile(data) {  
    console.log(data);
    return dispatch => {  
        return dispatch({  
            type: actionsType.MOBILE,  
            payload: data  
        });  
    }  
};  
 
// Password
export function password(data) {  
    return dispatch => {  
        return dispatch({  
            type: actionsType.PASSWORD,  
            payload: data  
        });  
    }  
};

// RECAPTCHA
export function reCaptch(data) {  
    return dispatch => {  
        return dispatch({  
            type: actionsType.RECAPTCHA,  
            payload: data  
        });  
    }  
};

// UserType

// State
export function State(data) { 
    return dispatch => {
        dispatch(getDistrictData(data))
        return dispatch({
            type: actionsType.STATE,
            payload: data
        })
    }
}
//City
export function City(data) {
    return dispatch => {
        return dispatch({
            type: actionsType.CITY,
            payload: data
        })
    }
}
//DiStrict
export function District(data) {
    return dispatch => {
        
        return dispatch({
            type: actionsType.DISTRICT,
            payload: data
        })
    }
}
//Sector
export function Sector(data) {
    return dispatch => {
        return dispatch({
            type: actionsType.SECTOR,
            payload: data
        })
    }
}


export function UserType(data) {  
    return dispatch => {  
        return dispatch({  
            type: actionsType.USER_TYPE,  
            payload: data  
        });  
    }  
};

export function DistrictList(data) {  
    return dispatch => {  
        return dispatch({  
            type: actionsType.DISTRICT_LIST,  
            payload: data  
        });  
    }  
};
export function toolsId(data) {  
    return dispatch => {  
        return dispatch({  
            type: actionsType.TOOLSID,  
            payload: data  
        });  
    }  
};

export const getDistrictData = (stateid) => {
    console.log(stateid)
    return dispatch=>{
    // this.props.District(e.target.value)
    var data = { stateid: stateid };
    var config = {
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/master/1/api/getdistrict`,
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
            'Content-Type': 'text/plain'
        },
        data: data
    };
    axios(config)
        .then(response => {
           dispatch(DistrictList(response.data.pd))
        })
        .catch(function (error) {
            console.log(error);
        });
    }
    
}
