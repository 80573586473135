import React, { Component } from 'react';
import axios from 'axios';
import $ from 'jquery';
import Token from '../Config/Config.json';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { connect } from 'react-redux';
import SectorName from '../../Sector/SectorName';
import EnterpriseLocation from '../../Address';
import Header from '../../Header/Header';

class RegistrationA extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bussinessData: [],
            enterpriseData: [],
            registerData: [],
            fields: {},
            errors: {},
            gender: 'Male',
            show: true,
            enterprise_type: '1',
            name: '',
            enterprise_name: '',
            token: localStorage.getItem('access_token'),
            user_type: '',
            password: '',
            email: '',
            message: '',
            statusCode: ''
        }
    }

    handleValidation() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        //Name
        if (this.state.name === '') {
            formIsValid = false;
            errors["name"] = "Please enter name";
            $("#contact-name").addClass('has-error');

        }
        else {
            $("#contact-name").removeClass("has-error");
        }
        //Email
        if (!fields["email"]) {
            formIsValid = false;
            errors["email"] = "Please fill email address";
            $("#msme-email").addClass('has-error');
        } else {
            $("#msme-email").removeClass("has-error");
        }
        if (typeof fields["email"] !== "undefined") {
            let lastAtPos = fields["email"].lastIndexOf('@');
            let lastDotPos = fields["email"].lastIndexOf('.');
            if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["email"].indexOf('@@') === -1 && lastDotPos > 2 && (fields["email"].length - lastDotPos) > 2)) {
                formIsValid = false;
                errors["email"] = "Email is not valid";
                $("#msme-email").addClass('has-error');
            }
            else {
                $("#msme-email").removeClass("has-error");

            }
        }

        this.setState({ errors: errors });
        return formIsValid;
    }
    isNumber = (evt) => {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }
    handleGeneralInfo(e) {
        e.preventDefault();

        if (this.handleValidation()) {
            const data = {
                name: e.target.name.value,
                email: e.target.email.value,
                phone: e.target.phone.value,


            }
            // console.log(data)
            this.setState({
                step1: data,
                name: '',
                show: false,
                // user_type: localStorage.getItem('userType'),
                // password: localStorage.getItem('password')

            })
        }

    }
    handleChange = (field, e) => {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({ fields });
    }
    getEnterpriseType = () => {
        var data = { id: -1 };
        var config = {
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}/master/1/api/getenterprisetype`,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
                'Content-Type': 'text/plain'
            },
            data: data
        };

        axios(config)
            .then((response) => {
                console.log(JSON.stringify(response.data));
                this.setState({
                    enterpriseData: response.data.pd
                })
            })
            .catch(function (error) {
                console.log(error);
            });

    }
    getBussinessData = () => {

        var data = { id: -1 };

        var config = {
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}/master/1/api/getenterprisecategory`,
            headers: {
                'Authorization': `Bearer ${this.state.token}`,
                'Content-Type': 'text/plain'
            },
            data: data
        };

        axios(config)
            .then(response => {

                this.setState({
                    bussinessData: response.data.pd
                })
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    componentDidMount() {
        this.getBussinessData();
        this.getEnterpriseType();
    }
    contactSubmit(e) {
        e.preventDefault();
        if (e.target.enterprise_name.value !== ''
            || this.state.enterprise_type !== ''
            || this.props.mobile !== ''
            || this.props.password !== ''
            || this.state.step1.name !== ''
            || this.props.state !== ''
            || this.props.city !== ''
            || this.props.district !== ''
            || this.props.sector !== ''
            || this.props.state !== ''
            || this.props.city !== ''
            || e.target.nature_of_bussiness.value !== '') {
            const data = {
                user_type: this.props.userType,
                mobileno: this.props.mobile,
                emailid: this.state.step1.email,
                msme_password: this.props.password,
                name: this.state.step1.name,
                state: this.props.state,
                city: this.props.city,
                district: this.props.district,
                enterprise_name: e.target.enterprise_name.value,
                sectorname: this.props.sector,
                enterprise_category: e.target.nature_of_bussiness.value,
                enterpries_type: this.state.enterprise_type,
            }

            console.log(data)
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_API_URL}/regapi/1/api/save-msmeregistation`,
                headers: {
                    'Authorization': `Bearer ${this.state.token}`,
                    'Content-Type': 'text/plain'
                },
                data: data
            };

            axios(config)
                .then(response => {
                    if (response.data.statusCode === 'S021') {
                        this.setState({
                            message: response.data.message,
                            statusCode: response.data.statusCode
                        })
                        window.$('#exampleModalCenter').modal('show');
                    }
                    else if(response.data.statusCode === 'S006')
                    {
                        this.setState({
                            message: response.data.message,
                            statusCode: response.data.statusCode
                        })
                        window.$('#exampleModalCenter').modal('show');
                    }
                    else{
                        this.setState({
                            message: response.data.message,
                            statusCode: response.data.statusCode
                        })
                        window.$('#exampleModalCenter').modal('show');
                    }
                    
                })
                .catch((err) => console.log(err))
        }
        else {
            this.setState({
                message: 'Please fill all mandatory!'
            })
            window.$('#exampleModalCenter').modal('show');
        }
        

    }

    moveNext = () => {
        if (this.state.statusCode === 'S006') {
            this.props.history.push('/')
        }
        else{
            this.props.history.push('/resigstration-step-one')
        }
    }
    render() {
        return (
            <React.Fragment>
                <Header/>
                <div className="modal fade" id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="popup text-center">
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                    {this.state.statusCode === 'S006'?
                                    <img src="./assets/img/ic_thumbs_up.svg" alt="Ok" />
                                    :<img src="./assets/img/ic_cross_mark.png" alt="Something wrong!" />}
                                    <p>{this.state.message}</p>
                                    <button type="button" onClick={() => this.moveNext()} className="btn  login" data-dismiss="modal">OKAY</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- registration form  --> */}
                <section className="registor-yourself">
                    {/* <!-- MultiStep Form --> */}
                    <div className="container" id="grad1">
                        <div className="row mt-0">
                            <div className="col-sm-3  ">
                                <div className="poster">
                                    <img src="assets/img/img_registration_form.svg" alt="" className="img-fluid" />
                                </div>

                                <div className="owl-carousel owl-theme">

                                    <div className="item">
                                        <div className="tag-line">
                                            <h3>
                                                <strong>Register</strong> to <br />
                                                <strong>empower</strong> & <br />
                                                <strong>accelerate</strong> your <br />
                                                <strong>business growth</strong>
                                            </h3>
                                        </div>

                                        <div className="counters">
                                            <div className="counter-one">
                                                <h4>1000+</h4>
                                                <p>Registered Users</p>
                                            </div>
                                            <div className="counter-one">
                                                <h4>70,000+</h4>
                                                <p>Registered MSMEs</p>
                                            </div>
                                            <div className="counter-one">
                                                <h4>25,000+</h4>
                                                <p>Aspiring Entrepreneurs Registered</p>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="item">
                                        <div className="tag-line">
                                            <h3>
                                                <strong>Register</strong> to <br />
                                                <strong>empower</strong> & <br />
                                                <strong>accelerate</strong> your <br />
                                                <strong>business growth</strong>
                                            </h3>
                                        </div>
                                        <div className="counters">
                                            <div className="counter-one">
                                                <h4>1 Lakhs +</h4>
                                                <p>Registered Users</p>
                                            </div>
                                            <div className="counter-one">
                                                <h4>70,000+</h4>
                                                <p>Registered MSMEs</p>
                                            </div>
                                            <div className="counter-one">
                                                <h4>25,000+</h4>
                                                <p>Aspiring Entrepreneurs Registered</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>


                            </div>
                            <div className="col-sm-9  text-center p-0 mt-3 mb-2">
                                <div className="card border-top-radius px-0 pb-0 mt-3 mb-3">
                                    <div className="sme-title" style={{ backgroundImage: 'url("assets/img/img_form_header.svg")', backgroundSize: 'cover' }}>
                                        <p>Welcome to India SME Services Platform</p>
                                        <h2>Registration form - <span className="exs-msme">Existing MSME </span></h2>
                                    </div>
                                    {/* <form name="contactform" onSubmit={this.contactSubmit.bind(this)}> */}
                                    <div className="row">

                                        {/* {this.state.show ? */}
                                        <div className="col-md-12 mx-0" style={{ display: this.state.show ? 'block' : 'none' }} id="tab1">
                                            <div id="msform">
                                                {/* <!-- progressbar --> */}
                                                <ul id="progressbar">
                                                    <li className="active" id="information">General Information</li>
                                                    <li id="enterprise">Enterprise Information</li>

                                                </ul>
                                                {/* <!-- fieldsets --> */}
                                                <form name="contactform" className="contactform" onSubmit={this.handleGeneralInfo.bind(this)}>
                                                    <fieldset>
                                                        <div className="container">
                                                            <div className="form-group row">
                                                                <label htmlFor="contPerson" className="col-sm-4 col-form-label">Contact Person Name <b className="text-danger">*</b></label>
                                                                <div className="col-sm-7 text-left">
                                                                    <input
                                                                        className="form-control-plaintext"
                                                                        id='contact-name'
                                                                        type="text"
                                                                        name='name'
                                                                        placeholder="Name"
                                                                        onChange={(e) => this.setState({ name: e.target.value })}
                                                                        onBlur={() => this.handleValidation()}
                                                                        defaultValue={this.state.name}
                                                                    />

                                                                </div>
                                                            </div>

                                                            <div className="form-group mobile-lock row">
                                                                <label htmlFor="" className="col-sm-4 col-form-label">Mobile No. <b className="text-danger">*</b></label>
                                                                <div className="col-sm-7 position-relative  text-left">
                                                                    <input
                                                                        className="form-control-plaintext"
                                                                        name='phone'
                                                                        refs="phone"
                                                                        type="tel"
                                                                        placeholder="Phone"
                                                                        disabled
                                                                        name='phone'
                                                                        value={this.props.mobile}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="form-group row">
                                                                <label htmlFor="" className="col-sm-4 col-form-label">Email <b className="text-danger">*</b></label>
                                                                <div className="col-sm-7  text-left">
                                                                    <input className='form-control-plaintext'
                                                                        id='msme-email'
                                                                        name='email'
                                                                        refs="email"
                                                                        type="text"
                                                                        placeholder="Email"
                                                                        onChange={(this.handleChange.bind(this, "email"))}
                                                                        onBlur={() => this.handleValidation()}
                                                                        defaultValue={this.state.fields["email"]}
                                                                    />
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className="form-group row">
                                                            <label htmlFor="" className="col-sm-4 col-form-label"></label>
                                                            <div className="col-sm-7  text-left">
                                                                <input type="submit"
                                                                    // onClick={()=>this.handleGeneralInfo()} 
                                                                    name="next"
                                                                    className="action-button"
                                                                    defaultValue="Next"
                                                                    id="nextbutton"
                                                                />
                                                            </div>
                                                        </div>
                                                    </fieldset>
                                                </form>

                                            </div>
                                        </div>
                                        <div className="col-md-12 mx-0" style={{ display: this.state.show ? 'none' : 'block' }} id="tab2">
                                            <div id="msform">
                                                {/* <!-- progrename:''ssbar --> */}
                                                <ul id="progressbar">
                                                    <li id="information">General Information</li>
                                                    <li className="active" id="enterprise">Enterprise Information</li>

                                                </ul>
                                                {/* <!-- fieldsets --> */}
                                                <form name="contactform" className="contactform" onSubmit={this.contactSubmit.bind(this)}>
                                                    <fieldset>
                                                        <div className="container">
                                                            <div className="form-group row">
                                                                <label htmlFor="" className="col-sm-4 col-form-label">Enterprise Name *</label>
                                                                <div className="col-sm-7 text-left">
                                                                    <input
                                                                        style={{ zIndex: 100 }}
                                                                        id='enterprise_name'
                                                                        className="form-control-plaintext"
                                                                        name='enterprise_name'
                                                                        ref="enterprise_name"
                                                                        type="text"
                                                                        placeholder="Enterprise Name"

                                                                    /><br />
                                                                    {/* <span style={{ color: "red" }}>{this.state.errors1["enterprise_name"]}</span> */}
                                                                </div>
                                                            </div>

                                                            <div className="form-group row">
                                                                <label htmlFor="" className="col-sm-4 col-form-label">Sector *</label>
                                                                <div className="col-sm-7  text-left">
                                                                    {/* Sector name */}
                                                                    <SectorName />
                                                                </div>
                                                            </div>
                                                            {/* Location state district city */}
                                                            <EnterpriseLocation />

                                                            <div className="form-group row">
                                                                <label htmlFor="" className="col-sm-4 col-form-label">Enterprise Type *</label>
                                                                <div className="col-sm-8 text-left">

                                                                    {this.state.enterpriseData.map((item, index) =>
                                                                        <div className="form-check d-inline-block" key={index}>
                                                                            {['bottom'].map((placement) => (
                                                                                <OverlayTrigger
                                                                                    key={placement}
                                                                                    placement={placement}
                                                                                    overlay={
                                                                                        <Tooltip id={`tooltip-${placement}`}>
                                                                                            {item.description}
                                                                                        </Tooltip>
                                                                                    }
                                                                                >
                                                                                    <label className="form-check-label" htmlFor="radio1">
                                                                                        <input type="radio" onChange={(e) => this.setState({ enterpries_type: e.target.value })} className="form-check-input" id="radio1" name="enterprise_type" defaultValue={item.enterprisetypecode} />{item.enterprisetypename}
                                                                                    </label>
                                                                                </OverlayTrigger>
                                                                            ))}

                                                                        </div>
                                                                    )}
                                                                </div>

                                                            </div>
                                                            <div className="form-group row">
                                                                <label htmlFor="" className="col-sm-4 col-form-label">Category of Enterprise *</label>
                                                                <div className="col-sm-7  text-left">
                                                                    <select
                                                                        className='form-control'
                                                                        name='nature_of_bussiness'
                                                                        type="text"
                                                                        placeholder="Nature_of_bussiness"
                                                                    >
                                                                        <option>Please Select</option>
                                                                        {this.state.bussinessData.map((item, index) => <option key={index} value={item.enterprisecategorycode}>{item.enterprisecategoryname}</option>)}

                                                                    </select><br />

                                                                </div>
                                                            </div>

                                                            <div className="form-group row">
                                                                <label htmlFor="" className="col-sm-4 col-form-label"></label>
                                                                <div className="col-sm-7  text-left">
                                                                    <input
                                                                        type="submit"
                                                                        name="next"
                                                                        className="next action-button"
                                                                        defaultValue="Complete Registration"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </fieldset>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <!-- registration form end  --> */}
            </React.Fragment>
        )
    }
}

const mapToState = state => {
    console.log('data', state)
    return {
        error: state.error,
        password: state.password,
        userType: state.userType,
        retoken: state.retoken,
        mobile: state.mobile,
        city: state.city,
        district: state.district,
        state: state.state,
        sector: state.sector
    }
}


export default connect(mapToState, null)(RegistrationA);
