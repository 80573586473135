import React, { Component } from 'react';
import { dispatchLogout } from "../Auth/Login/actions/sign-out";
import { sendAuthorizationRequest, sendTokenRequest } from "../Auth/Login/actions/sign-in";
import ReactJson from 'react-json-view'
// import { dispatchLogout } from "../Auth/Login/actions/sign-out";
import { isValidSession, getAllSessionParameters, decodeIdToken } from "../Auth/Login/actions/session";
import SliderLeftSide from '../Auth/Login/Slider_left_side';
class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            idToken: {},
            tokenResponse: {},
            isLoggedIn: false,
            isTrue: false
        }
    }

    componentWillMount() {
        // See if there is a valid session.
        if (isValidSession()) {
            const session = getAllSessionParameters();
            const _tokenResponse = {
                access_token: session.ACCESS_TOKEN,
                refresh_token: session.REFRESH_TOKEN,
                scope: session.SCOPE,
                id_token: session.ID_TOKEN,
                token_type: session.TOKEN_TYPE,
                expires_in: parseInt(session.EXPIRES_IN),
            };
            this.setState({
                tokenResponse: _tokenResponse,
                idToken: decodeIdToken(session.ID_TOKEN),
                isLoggedIn: true
            }, () => {
                sessionStorage.setItem('tokenId', JSON.stringify(this.state.tokenResponse));
                sessionStorage.setItem('tokenResponse', JSON.stringify(this.state.idToken));
                localStorage.setItem('tokenId', JSON.stringify(this.state.idToken.given_name));
                localStorage.setItem('tokenResponse', JSON.stringify(this.state.tokenResponse));
                this.setState({
                    name: this.state.idToken.given_name
                })
            });

            return;
        }

        // Reads the URL and retrieves the `code` param.
        const code = new URL(window.location.href).searchParams.get("code");

        // If a authorization code exists, sends a token request.
        if (code) {
            sendTokenRequest(code)
                .then(response => {

                    sessionStorage.setItem('tokenData', JSON.stringify(response));
                    window.sessionStorage.setItem('tokenData', JSON.stringify(response))
                    console.log("TOKEN REQUEST SUCCESS", response);
                    this.setState({
                        tokenResponse: response[0],
                        idToken: response[1],
                        isLoggedIn: true,

                    })


                })
                .catch((error => {
                    console.log("TOKEN REQUEST ERROR", error);
                    this.setState({ isLoggedIn: false });
                }));
        }
    }
    // componentDidMount() {
    //     if (sessionStorage.getItem('time') === null) {
    //         setTimeout(() => {
    //             sessionStorage.setItem('tokenId1', JSON.stringify(this.state.idToken));
    //             sessionStorage.setItem('tokenResponse1', JSON.stringify(this.state.tokenResponse));
    //             sessionStorage.setItem('time', '2');
    //             localStorage.setItem('tokenId1', JSON.stringify(this.state.idToken));
    //             localStorage.setItem('tokenResponse1', JSON.stringify(this.state.tokenResponse));
    //             localStorage.setItem('time', '2');
    //             window.location.href = '/dashboard';
    //         }, 500)
    //     }
    // }

    handleLogoutBtnClick = () => {

        localStorage.removeItem('tokenId');
        localStorage.removeItem('tokenResponse1');
        localStorage.removeItem('tokenResponse');
        localStorage.removeItem('access_token')
        localStorage.clear();
        sessionStorage.removeItem('tokenId');
        sessionStorage.removeItem('tokenResponse1');
        sessionStorage.removeItem('tokenResponse');
        sessionStorage.removeItem('access_token')
        sessionStorage.clear();
        dispatchLogout();
    };
    render() {
        const { tokenResponse, idToken, isLoggedIn } = this.state;
        return (
            <div>
                {
                    isLoggedIn ?
                        <>
                            <header className="site-header">
                                <div className="container">

                                    <div className="sidbi-header">
                                        <nav className="navbar navbar-expand-lg navbar-light">
                                            <a className="navbar-brand" href="/"><img src="assetsde/images/logo.png" alt="" /></a>
                                            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                                <span className="navbar-toggler-icon"></span>
                                            </button>

                                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                                <form className="form-inline my-2 my-lg-0 custom_form">
                                                    <select className="selectpicker" data-show-subtext="true" data-live-search="true">
                                                        <option data-subtext="Demo-one">All</option>
                                                        <option data-subtext="Demo-one">01</option>
                                                        <option data-subtext="Demo-one">02</option>
                                                        <option data-subtext="Demo-one">03</option>
                                                        <option data-subtext="Demo-one">04</option>

                                                    </select>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control" placeholder="Search all resources" />
                                                        <div className="input-group-append">
                                                            <button className="btn btn-secondary search_secondry" type="button">
                                                                <i className="fa fa-search"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </form>
                                                <ul className="zoom">
                                                    <li><button className="minus">A-</button></li>
                                                    <li><p id="per">100%</p></li>
                                                    <li><button className="plus">A+</button> </li>
                                                </ul>
                                                <ul className="navbar-nav mr-auto ">
                                                    <li className="nav-item dropdown bordr-rgt">
                                                        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            English
                                            </a>
                                                        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                            <a className="dropdown-item" href="#">Action</a>
                                                            <a className="dropdown-item" href="#">Another action</a>
                                                            <a className="dropdown-item" href="#">Something else here</a>
                                                        </div>
                                                    </li>
                                                    <li className="nav-item bordr-rgt">
                                                        <a className="nav-link" href="#">Contact</a>
                                                    </li>

                                                </ul>
                                                <ul className="navbar_icon navbar-nav ml-auto nav-flex-icons">
                                                    <li className="nav-item">
                                                        <a className="facebook" href="#"><img src="assetsde/images/ic_facebook_logo.svg" alt="" /></a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a className="facebook" href="#"><img src="assetsde/images/ic_twitter_logo.svg" alt="" /></a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a className="facebook" href="#"><img src="assetsde/images/ic_youtube_logo.svg" alt="" /></a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a className="facebook" href="#"><img src="assetsde/images/ic_instagram_logo.svg" alt="" /></a>
                                                    </li>
                                                    <li className="nav-item">
                                                        <a className="facebook" href="#"><img src="assetsde/images/ic_linkedin_logo.svg" alt="" /></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </nav>

                                    </div>
                                    <section id="header-bar">
                                        <div className="topnav">
                                            <ul className="navbar-nav mr-auto align-items-center">
                                                <li><a className="bordr-rgt" href="#"> ABOUT US</a></li>
                                                <li className="nav-item dropdown">
                                                    <a className="nav-link dropdown-toggle " href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        SERVICES
                                            </a>
                                                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                        <a className="dropdown-item" href="#">Action</a>
                                                        <a className="dropdown-item" href="#">Another action</a>
                                                        <a className="dropdown-item" href="#">Something else here</a>
                                                    </div>
                                                </li>


                                            </ul>

                                            <ul className="right_section">
                                                <li>

                                                    <button type="button" style={{
                                                        backgroundColor: '#000',
                                                        fontSize: '15px',
                                                        color: '#fff',
                                                        fontFamily: 'poppinssemibold',
                                                        borderRadius: '5px',
                                                        paddingTop: '7px',
                                                        paddingBottom: '7px',
                                                        paddingLeft: '3px',
                                                        paddingRight: '3px'
                                                    }} className="search_secondry1 dropdown-toggle" data-toggle="dropdown">
                                                        {this.state.name}
                                                    </button>
                                                    <div className="dropdown-menu">
                                                        <div className="card">

                                                            <div className="row">
                                                                <div className="col-sm-6">
                                                                    <div className="loginas" onClick={() => window.location.href = '/'}>
                                                                        <img src="assetsde/images/ic_MSME.svg" className="img-responsive" />
                                                                        <p>MSME</p>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-6">
                                                                    <div className="loginas" onClick={() => window.location.href = '/'}>
                                                                        <img src="assetsde/images/ic_aspiring_entreprenuer.svg" className="img-responsive" />
                                                                        <p>Aspiring Entrepreneur</p>
                                                                    </div>
                                                                </div>
                                                                <hr />

                                                                <a href='/registration-step-one' className='ml-4 my-4 py-2 px-2'>Register Now</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li><a href="#"> TAKE A TOUR</a></li>

                                            </ul>

                                        </div>
                                    </section>
                                </div>
                            </header>
                            <section className="sdbi-banner">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-sm-6 ">
                                            <SliderLeftSide />
                                        </div>

                                        <div className="col-sm-6">
                                            <div className="registration">

                                                <h2>Welcome {this.state.name}</h2>
                                                <button className="btn btn-danger" onClick={this.handleLogoutBtnClick}>LOGOUT</button>

                                            </div>
                                        </div>
                                        {/* <button className="btn btn-danger" onClick={this.handleLogoutBtnClick}>LOGOUT</button> */}

                                    </div>
                                </div>


                                {/* } */}
                            </section>
                        </>
                        :<span>{window.location.href='/dashboard'}</span>
                        
                }
            </div>

        )
    }
}
export default Dashboard;