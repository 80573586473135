import React, { Component } from 'react';
import Recaptcha from './Googlerecaptcha';
import axios from 'axios';
import Token from '../Config/Config.json';
import SliderLeftSide from '../Login/Slider_left_side';
import { connect } from 'react-redux';
import * as action from '../../Store/Actions/Actions';
import $ from 'jquery';
import Header from '../../Header/Header';

class NewRegistration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: {},
            errors: {},
            message:'',
            statusCode:''
        }
    }

    handleValidation = () => {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        //phone
        if (!fields["phone"]) {
            formIsValid = false;
            
            errors["phone"] = "Please enter phone number";
            window.$('#exampleModalCenter').modal('show'); 
            
            this.setState({
                message:'Please enter valid phone number'
            })
            $("#phone" ).addClass('has-error');


        }
        else if (fields["phone"].length < 10) {
            formIsValid = false;
            errors["phone"] = "Phone number must be 10 digits";
            window.$('#exampleModalCenter').modal('show'); 
            
            this.setState({
                message:'Phone number must be 10 digits'
            })

            $("#phone" ).addClass('has-error');
        }
        else if (isNaN(fields["phone"])) {
            console.log('phone')
            formIsValid = false;
            errors["phone"] = "Phone number must be a number";
            window.$('#exampleModalCenter').modal('show'); 
            
            this.setState({
                message:'Phone number must be a number'
            })

            $("#phone" ).addClass('has-error');
        }
        this.setState({
            errors: errors
        })
        return formIsValid
    }

    isNumber = (evt) => {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }

    handleChange = (field, e) => {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({ fields });
    }
    handleSubmit = (e) => {
        e.preventDefault();
        if(this.props.retoken !== null){
            if (this.handleValidation()) {
                var data = { mobileno: e.target.phone.value };
                this.props.Mobile(e.target.phone.value)
                // localStorage.setItem('mobile',e.target.phone.value)
                var config = {
                    method: 'post',
                    url: `${process.env.REACT_APP_API_URL}/regapi/1/api/save-otp`,
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('access_token')} `,
                        'Content-Type': 'text/plain'
                    },
                    data: data
                };
    
                axios(config)
                    .then((response) => {
                        console.log(JSON.stringify(response.data));
                        if (response.data.statusCode === "S015")
                        {
                            localStorage.setItem('otpdata',JSON.stringify(response.data.pd))
                            this.props.Mobile(e.target.phone.value);
                            // this.props.history.push('/otp');
                            this.setState({
                                statusCode:response.data.statusCode,
                                message:response.data.message,
                                

                            })
                            window.$('#exampleModalCenter').modal('show');
                        }
                        else{
                            this.setState({
                                statusCode:'',
                                message:response.data.message,
                            })
                            window.$('#exampleModalCenter').modal('show'); 
                            $( "#phone" ).addClass( 'has-error');
                        }
                        
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }
            else{
                window.$('#exampleModalCenter').modal('show'); 
            
                this.setState({
                    message:'Invalid !'
                })
            }
        }
        else{
            window.$('#exampleModalCenter').modal('show'); 
            
            this.setState({
                message:'incorrect CAPTCHA!'
            })
        }
       

    }

    moveNext=()=>{
        if (this.state.statusCode=== "S015")
            {
                window.location.href='/otp';
            }
    }
    render() {
        return (
            <React.Fragment>
                <Header/>
                <div className="modal fade" id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="popup text-center">
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                    {this.state.statusCode === 'S015'?<img src="./assets/img/ic_thumbs_up.svg" alt="" />:
                                    <img src="./assets/img/ic_cross_mark.png" height='50px' alt="wrong" />}
                                    <p>{this.state.message}</p>
                                    <button type="button" onClick={()=>this.moveNext()} className="btn  login" data-dismiss="modal">OKAY</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    {/* <!-- Hero Banner --> */}
                    <section className="sdbi-banner">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-6 ">
                                    <SliderLeftSide />
                                </div>
                                <div className="col-sm-6">
                                    <div className="registration">

                                        <h2>Register Now!</h2>
                                        <form className="" onSubmit={this.handleSubmit}>
                                            <div className="form-group mobile-form-group">
                                                <label>Mobile No.</label>
                                                <div className='row'>
                                                    <input type="text" className="form-control register-mob" placeholder="+91" disabled
                                                        style={{ height: '37px' }} />
                                              
                                                    <input
                                                        className="form-control registration-login"
                                                        id='phone'
                                                        refs="phone"
                                                        onKeyPress={(e) => this.isNumber(e)}
                                                        type="tel" maxLength={10}
                                                        placeholder="Enter your mobile number"
                                                        name='phone'
                                                        onChange={this.handleChange.bind(this, "phone")}
                                                        onBlur={() => this.handleValidation()}
                                                        value={this.state.fields["phone"]}
                                                    />
                                                    
                                                    {/* <input 
                                type="text" className="form-control" placeholder="Enter your mobile number" required/> */}
                                                </div>
                                            </div>
                                            <div className="captcha">
                                                <p>Captcha Verification</p>
                                                <Recaptcha event={''} />
                                            </div>
                                            <div className="form-group row">

                                                <div className="col-sm-7  text-left">
                                                    <input
                                                        type="submit"
                                                        name="next"
                                                        className="btn  login"
                                                        value="Next"
                                                    />
                                                </div>
                                            </div>
                                        </form>

                                    </div>

                                    <p className="new-user">Already User?<a className="register-nows" href="/"> Login Now</a></p>
                                </div>


                            </div>
                        </div>
                    </section>
                    {/* <!-- Hero Banner --> */}
                </div>
            </React.Fragment>
        )
    }
}


const mapToState = state => {
    console.log(state)
    return {
        error: state.error,
        retoken:state.retoken
    }
}

const mapToDispatch = dispatch => {
    return {
        Mobile: (data) => dispatch(action.mobile(data)),

    }
}

export default connect(mapToState, mapToDispatch)(NewRegistration);
// export default ;