import React, { Component } from 'react';
import axios from 'axios';
import Token from '../Config/Config.json';
import { Carousel } from 'react-bootstrap'

class SliderLeftSide extends Component {
    constructor(props) {
        super(props);
        this.state = {
            registerData: [],
        }
    }
    getRegisterData = () => {
        const data = { id: -1 }
        var config = {
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}/kpi/1/kpi/getkpiregister`,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
                'Access-Control-Allow-Origin': '*',
                
            },
            data: data
        };


        axios(config)
            .then((response) => {
                console.log(JSON.stringify(response.data));
                this.setState({
                    registerData: response.data.pd
                })
            })
            .catch((error) => {
                console.log(error);
            });
    }
    componentDidMount() {
        this.getRegisterData();
    }
    render() {
        return (
            <div className="main_banner">

                <div className="banner">
                    <div className="banner-title">
                        <h3>Why <span className="register"> Register</span></h3>
                        <p><span className="on">on </span> India SME Services Platform?</p>
                        {/* <!-- <ol className="carousel-indicators">
                                <li data-target="#myCarousel" data-slide-to="0" className="active"></li>
                                <li data-target="#myCarousel" data-slide-to="1"></li>
                                <li data-target="#myCarousel" data-slide-to="2"></li>
                            </ol>--> */}
                        <div className="banner-img">
                            <img src="assets/img/login_onboarding_img_1_a.png" alt="" className="img-fluid" />
                        </div>
                        
                        
                       <div className="owl-carousel owl-theme">
                       <Carousel interval={2000}>
                            {this.state.registerData.map((item, index) =>
                                <Carousel.Item>
                                    <div className="count">
                                        <p className="hunderd">{item.value}+</p>
                                        <p className="leader">{item.home_api_code}</p>
                                    </div>
                                </Carousel.Item>
                            )}
                        </Carousel>
                        {/* <div className="item">
                            <div className="count">
                                <p className="hunderd">100+</p>
                                <p className="leader">Lenders Onboarded</p>
                            </div>
                        </div>
                        <div className="item">
                            <div className="count">
                                <p className="hunderd">100+</p>
                                <p className="leader">Lenders Onboarded</p>
                            </div>
                        </div>
                        <div className="item">
                            <div className="count">
                                <p className="hunderd">100+</p>
                                <p className="leader">Lenders Onboarded</p>
                            </div>
                        </div>
                        <div className="item">
                            <div className="count">
                                <p className="hunderd">100+</p>
                                <p className="leader">Lenders Onboarded</p>
                            </div>
                        </div>
                        <div className="item">
                            <div className="count">
                                <p className="hunderd">100+</p>
                                <p className="leader">Lenders Onboarded</p>
                            </div>
                        </div>*/}
                        </div> 

                        <p className="banner-footer">A virtual ecosystem promoting and fostering Growth of MSMEs</p>
                    </div>
                </div>
            </div>
        )
    }
}
export default SliderLeftSide;