import React, { Component } from 'react';
import axios from 'axios';
import $ from 'jquery';
import Token from '../Config/Config.json';
import SliderLeftSide from '../Login/Slider_left_side';
import { connect } from 'react-redux';
import * as action from '../../Store/Actions/Actions';
import RegisterWithValidation from '../Register/RegisterWithValidation';
import Header from '../../Header/Header';

class Otp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      errors: {},
      message: '',
      statusCode: ''
    }
  }

  handleValidation = () => {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    if (!fields["otp1"] && !fields["otp2"] && !fields["otp3"] && !fields["otp4"]) {
      formIsValid = false;
      errors["otp1"] = "Please enter otp!";
      this.setState({
        message: 'Please enter confirm password!'
      })
    }
    if (!fields["otp1"]) {
      $("#txtOtp1").addClass('has-error');
    }
    else {
      $("#txtOtp1").removeClass("has-error");
    }
    if (!fields["otp2"]) {
      $("#txtOtp2").addClass('has-error');
    }
    else {
      $("#txtOtp2").removeClass("has-error");
    }
    if (!fields["otp3"]) {
      $("#txtOtp3").addClass('has-error');
    }
    else {
      $("#txtOtp3").removeClass("has-error");
    }
    if (!fields["otp4"]) {
      $("#txtOtp4").addClass('has-error');
    }
    else {
      $("#txtOtp4").removeClass("has-error");
    }
    this.setState({
      errors: errors
    })
    return formIsValid
  }

  handleChange = (field, e) => {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }
  handleSubmit = (e) => {
    e.preventDefault();
    const pass1 = localStorage.getItem('firstpassowrd')
    const pass2 = localStorage.getItem('secondPassword')
    if (e.target.otp1.value !== '' || e.target.otp2.value !== '' || e.target.otp3.value !== '' || e.target.otp4.value === '') {
      var regularExpression = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;

      if (regularExpression.test(pass1)) {
        if (pass1 !== pass2) {
          let errors1 = {};
          errors1["password3"] = "Password & confirm password did not matched!";
          $("#password2").addClass('has-error');
          $("#password1").addClass('has-error');
          this.setState({
            errors: errors1,
            message: 'Password & confirm password did not matched!'
          })
          window.$('#exampleModalCenter').modal('show');
        }
        else {
          var mobile_number = this.props.mobile;
          var data = {
            otp_value1: e.target.otp1.value,
            otp_value2: e.target.otp2.value,
            otp_value3: e.target.otp3.value,
            otp_value4: e.target.otp4.value,
            password: pass1,
            confpass: pass2,
            mobileno: mobile_number
          };

          var config = {
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}/regapi/1/api/getvotp`,
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('access_token')} `,
              'Access-Control-Allow-Origin': '*',
              'Content-Type': 'text/plain'
            },
            data: data
          };

          axios(config)
            .then((response) => {

              if (response.data.statusCode === "F030") {
                this.setState({
                  statusCode: response.data.statusCode,
                  message: response.data.Note
                })

                window.$('#exampleModalCenter').modal('show')
              }
              else if (response.data.statusCode === "S020") {
                this.props.Password(pass1)
                this.setState({
                  statusCode: response.data.statusCode,
                  message: response.data.message
                })

                window.$('#exampleModalCenter').modal('show')
              }
              else {
                window.$('#exampleModalCenter').modal('show');
                this.setState({
                  statusCode: response.data.statusCode,
                  message: response.data.message
                })
              }
            })
            .catch(function (error) {
              console.log(error);
            });

        }
      } else {
        this.setState({
          message: 'Enter valid password!'
        })
        window.$('#exampleModalCenter').modal('show');
      }

    }
    else {
      this.setState({
        message: 'Enter valid OTP!'
      })
      window.$('#exampleModalCenter').modal('show');
    }
  }

  moveNext = () => {
    if (this.state.statusCode === 'S020') {
      this.props.history.push('/who-are-you')
    }
  }

  handleResend = () => {
    var data = { mobileno: this.props.mobile };
    var config = {
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/regapi/1/api/save-otp`,
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('access_token')} `,
        'Content-Type': 'text/plain'
      },
      data: data
    };

    axios(config)
      .then((response) => {
        if (response.data.statusCode === "S015") {
          localStorage.setItem('otpdata', JSON.stringify(response.data.pd))
          // this.props.history.push('/otp');
          this.setState({
            statusCode: response.data.statusCode,
            message: response.data.message,


          })
        }
      })
      .catch(err => console.log(err))
  }

  render() {
    return (
      <React.Fragment>
        <Header/>
        <div className="modal fade" id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <div className="popup text-center">
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                  {this.state.statusCode === 'S020' ?
                    <img src="./assets/img/ic_thumbs_up.svg" alt="" />
                    : <img src="./assets/img/ic_cross_mark.png" height='50px' alt="wrong" />
                  }

                  <p>{this.state.message}</p>
                  <button onClick={() => this.moveNext()} type="button" className="btn  login" data-dismiss="modal">OKAY</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Hero Banner --> */}
        <section className="sdbi-banner">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <SliderLeftSide />
              </div>
              <div className="col-sm-6">
                <div className="registration">
                  <form className="log-form" onSubmit={this.handleSubmit}>
                    <div className="form-group otp-group">
                      <label>Enter your OTP</label>
                      <input type="text"
                        onChange={this.handleChange.bind(this, "otp1")}

                        onBlur={() => this.handleValidation()} min={0} maxLength={1} name='otp1' className="form-control otp-input" id="txtOtp1" data-next="txtOtp2" />
                      <input type="text" onChange={this.handleChange.bind(this, "otp2")}
                        onBlur={() => this.handleValidation()} min={0} maxLength={1} name='otp2' className="form-control ml-2 otp-input" id="txtOtp2" data-next="txtOtp3" />
                      <input type="text" onChange={this.handleChange.bind(this, "otp3")}
                        onBlur={() => this.handleValidation()} min={0} maxLength={1} name='otp3' className="form-control ml-2 otp-input" id="txtOtp3" data-next="txtOtp4" />
                      <input type="text" onChange={this.handleChange.bind(this, "otp4")}
                        onBlur={() => this.handleValidation()} min={0} maxLength={1} name='otp4' className="form-control ml-2" id="txtOtp4" />
                      <div className="valid-feedback">Valid.</div>

                      <div className="invalid-feedback">Please fill out this field.</div>
                    </div>
                    <button type="button" onClick={() => this.handleResend()} className="btn login mb-2">Resend OTP</button>
                    <RegisterWithValidation />

                    <button type="submit" className="btn login mt-2">Verify OTP</button>
                  </form>

                </div>

                {/* <p className="new-user">Already have an account?<a className="register-nows" href="!#">Back to Login</a></p> */}
              </div>


            </div>
          </div>
        </section>
        {/* <!-- Hero Banner --> */}

      </React.Fragment>
    )
  }
}

const mapToState = state => {
  console.log(state)
  return {
    error: state.error,
    mobile: state.mobile

  }
}

const mapToDispatch = dispatch => {
  return {
    Password: (data) => dispatch(action.password(data)),
  }
}

export default connect(mapToState, mapToDispatch)(Otp);
