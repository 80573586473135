import React from 'react';



class HowItWorks extends React.Component {
    render() {
        return (
            <React.Fragment>
                <section className="main_sec sec_padd">
      		<div className="main_works">
	      		<div className="container">
	      			<div className="row">
	      				<div className="col-md-12">
	      					<div className="work_proses">
	      						<h3>How It <span>Work</span></h3>
	      					</div>
	      				</div>
	      			</div>
	      			<div className="row">
	      				<div className="col-md-4 sem_con">
	      					<div className="prosec_inner grn_col">
	      						<h2>1</h2>
	      						<h6>Search the service</h6>
	      						<p>Lorem ipsum is placeholder text commonly used in the graphic.</p>
	      					</div>
	      				</div>
	      				<div className="col-md-4 sem_con sem_clk">
	      					<div className="prosec_inner pin_col">
	      						<h2>2</h2>
	      						<h6>Pick your Service</h6>
	      						<p>Lorem ipsum is placeholder text commonly used in the graphic.</p>
	      					</div>
	      				</div>
	      				<div className="col-md-4 sem_con ">
	      					<div className="prosec_inner org_col">
	      						<h2>3</h2>
	      						<h6>Get your Service</h6>
	      						<p>Lorem ipsum is placeholder text commonly used in the graphic.</p>
	      					</div>
	      				</div>
	      			</div>
	      		</div>
	            <div className="clr"></div>
            </div>
      	</section>
            </React.Fragment>
        )
    }
}

export default HowItWorks;