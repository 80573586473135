import { Switch, Route, } from "react-router-dom";
import DeFooter from '../Defooter/DeFooter';
import SearchResult from '../SearchResult/SearchResult';
import DigitalHeader from '../DigitalHeader/DigitalHeader';
import DigitalPage from '../DigitalPage/DigitalPage';

export const DeRoute = () => {
    return (
        <div>
            <Switch>
                <Route exact path='/digitalpage' component={DigitalPage} />
                <Route exact path='/tools-details' component={SearchResult} />
            </Switch>
        </div>

    )
}