import React from 'react';
import DigitalHeader from '../DigitalHeader/DigitalHeader'
import BannerLower from '../Section/BannerLower';
import BannerSection from '../Section/BannerSection';
import CategorySection from '../Section/CategoriesSection';
import CircleStats from '../Section/CircleStats';
import HowItWorks from '../Section/HowItWorks';
import DeFooter from '../Defooter/DeFooter';



class DigitalPage extends React.Component {
    render() {
        return (
            <React.Fragment>
                <DigitalHeader/>
                <BannerSection/>
                <BannerLower/>
                <CategorySection/>
                <CircleStats/>
                <HowItWorks/>
                <DeFooter/>
            </React.Fragment>
        )
    }
}

export default DigitalPage;